import React, { useState, useEffect } from 'react';
import type { FtPageProps } from '../first/ftTypes';
import Ft from '../first/ft';
import FtRapi from '../first/ftRapi';
import FtFrame from '../first/ftFrame';
import FtCanvas from '../first/ftCanvas';
import FtSpacer from '../first/ftSpacer';
import FtForm from '../first/ftForm';
import FtText from '../first/ftText';
import FtFmSubmit from '../first/ftFmSubmit';
import FtFmTextField from '../first/ftFmTextField';
import FtFmDropdownField from '../first/ftFmDropdownField';
import FtFmSearchField from '../first/ftFmSearchField';
import { ftBusy } from '../first/ftBusy';
import { ftPopupMessage } from '../first/ftPopupMessage';
import FtAppInfo from '../first/ftAppInfo';
import Ut from '../general/ut';
import Th from '../general/th';

import GenLookup                          from '../models/genLookup';
import Attendee                           from '../models/attendee';
import ConfRegisterStatus                 from '../models/confRegisterStatus';

// const merchantId: string = "10033961";                              // TEST
// const merchantKey: string = "0lzxser40knnv";                        // TEST
// const payUrl: string = "https://sandbox.payfast.co.za/eng/process"; // TEST
// const passphrase: string = "saltandpepper";                         // TEST
// const payAmnt: string = "10.00";                                    // TEST

const merchantId: string = "10304296";                              // LIVE
const merchantKey: string = "22hl0wa6omrtl";                        // LIVE
const payUrl: string = "https://www.payfast.co.za/eng/process";     // LIVE
const passphrase: string = "fhJhfyr/.-hn1234gGdhjuk124";            // LIVE
const payAmnt: string = "450.00";                                   // LIVE

//********************************************************************
// PageAttendeeNewOnline
// @FTGEN_PROGRESS: done
// ...todo: specify props here...
export default function PageAttendeeNewOnline( { navigation, options, url }: FtPageProps ): JSX.Element
{
    //====================================================================
    // hooks: no conditions here

    //- hooks: state -----
    const nav = Ft.useNav( navigation );
    const [ popup                  , setPopup                  ] = useState( <></> ); // placeholder for any popup message/options
    const [ busy                   , setBusy                   ] = useState( false );
    const [ lkpAssembly            , setLkpAssembly            ] = useState( [] as GenLookup[] );
    const [ lkpRegion              , setLkpRegion              ] = useState( [] as GenLookup[] );
    const [ lkpTitle               , setLkpTitle               ] = useState( [] as GenLookup[] );
    const [ lkpProvince            , setLkpProvince            ] = useState( [] as GenLookup[] );
    const [ lkpContact             , setLkpContact             ] = useState( [] as GenLookup[] );
    const [ lkpPayMethod           , setLkpPayMethod           ] = useState( [] as GenLookup[] );
    const [ lkpAttType             , setLkpAttType             ] = useState( [] as GenLookup[] );
    const [ lkpRegStatus           , setLkpRegStatus           ] = useState( [] as GenLookup[] );
    const [ mdlAttendee            , setMdlAttendee            ] = useState<Attendee>( new Attendee() );
    const [ mode                   , setMode                   ] = useState<Number>( 0 ); // 0= check, 1= registered-paid, 2= registered-not-paid, 3= not-registered

    //- hooks: effect/startup -----
    useEffect( onLoad, [] );

    //====================================================================
    // render

    if ( busy || Ft.eAny( lkpAssembly, lkpRegion, lkpTitle, lkpProvince, lkpContact, lkpPayMethod, lkpAttType, lkpRegStatus ) ) return ftBusy( { popup: popup } );

    const frame: any = {
        ss         : '12',
        md         : 'c',
        lt         : 'a,c',
        txtDescr   : '20,B#ffffff;Helvetica',
        headBkgClr : '#005493',
        // bodyBor    : '2,#d4ab50',
        marB       : 20,
    };

    const fld: any = {
        ext         : '0|0|1|1|1',
        ss          : '12|12|6|6|6',
        labSs       : '0|4|4|4|4',
        bkgClr      : '#ffffff',
        pad         : '3,5,3,5',
        labMd       : 'r',
        labLt       : 'z,c',
        labTxtDescr : '16,#000000',
        labPad      : '16,5,0,5',
    };

    const fldSm: any = {
        ext         : '0|0|1|1|1',
        ss          : '6|6|3|3|3',
        labSs       : '0|0|4|4|4',
        bkgClr      : '#ffffff',
        pad         : '3,5,3,5',
        labMd       : 'r',
        labLt       : 'z,c',
        labTxtDescr : '16,#000000',
        labPad      : '16,5,0,5',
    };    

    const txtProps: any = {
        ss: '12|10|8|8|8',
        md: 'rw',
        lt: 'c,c',
    };

    return (<FtCanvas {...Th.page}>
        {popup}

        <FtFrame text='Check Registration' {...frame} show={mode == 0}>
            <FtSpacer h={10}/>
            <FtText {...txtProps} txtDescr='22,WCO#273891' 
                text='Welcome to the AFM GBM 2024 registrations.' 
            />
            <FtText {...txtProps} txtDescr='18,WCO#273891' 
                text='Please enter your ID number below to check your registration. If you have not registered yet, the registration form will open.' 
            />
            <FtText {...txtProps} txtDescr='18,WCO#273891' 
                text='If you have registered, but not paid yet, there will be an option to pay.'
            />
            <FtSpacer h={10}/>

            <FtForm {...Th.form}
                ss='12|10|10|10|10'
                models={{ m: mdlAttendee }}
                onSubmit={onIdCheck}
            >
                <FtFmTextField  name='m.rsaid_no'  label='ID Number' ftreq {...fld} />
                <FtSpacer />
                <FtFmSubmit ss='3:6:3' label='Check Registration' {...Th.button} />
                <FtSpacer />
            </FtForm>
        </FtFrame>

        <FtFrame text='Registration Success' {...frame} show={mode == 1}>
            <FtForm {...Th.form}
                ss='12|10|10|10|10'
                models={{ m: mdlAttendee }}
                onSubmit={() => {}}
            >
                <FtText {...txtProps} ss='12' txtDescr='22,WCO#273891' text={`ID Number : ${mdlAttendee?.rsaid_no}`} />
                <FtSpacer h={10} />
                <FtText txtDescr='20,WCO#273891' text='Thank you, you have successfully registered and your payment received.' />
                <FtSpacer />
                <FtFmSubmit ss='1:4:1' label='Resend Confirmation Letter' {...Th.button} onSubmit={onResend} />
                <FtSpacer />
            </FtForm>
        </FtFrame>        

        <FtFrame text='Registration Payment' {...frame} show={mode == 2}>
            <FtForm {...Th.form}
                ss='12|10|10|10|10'
                models={{ m: {} }}
                onSubmit={()=>{}}
            >
                <FtText {...txtProps} ss='12' txtDescr='22,WCO#273891' text={`ID Number : ${mdlAttendee?.rsaid_no}`} />
                <FtSpacer h={10} />
                <FtText ss='12' md='r' lt='c,c' txtDescr='20,W#404040' text='You have successfully registered, but we have not received your payment yet.' />
                <FtSpacer />
                <FtFmSubmit ss='1:4:1' label='Resend Confirmation Letter' {...Th.button} onSubmit={onResend} />
                <FtFmSubmit ss='1:4:1' label='Pay Now'                    {...Th.button} onSubmit={onPayNow} />
                <FtSpacer />
            </FtForm>
        </FtFrame>                

        <FtFrame text='New Registration' {...frame} show={mode == 3}>
            <FtForm {...Th.form}
                ss='12|10|10|10|10'
                models={{ m: mdlAttendee }}
                onSubmit={onSaveAndPay}
            >
                <FtFmTextField      name='m.rsaid_no'            label='ID Number'                ftreq {...fld} />
                <FtSpacer />
                <FtFmDropdownField  name='m.title_id'            label='Title'                    ftreq {...fld} data={lkpTitle} />
                <FtFmTextField      name='m.fullnames'           label='Full Names'               ftreq {...fld} />
                <FtFmTextField      name='m.surname'             label='Surname'                  ftreq {...fld} />
                <FtFmTextField      name='m.postal_adr_1'        label='Postal Address'           ftreq {...fld} />
                <FtFmTextField      name='m.postal_adr_2'        label=''                               {...fld} />
                <FtFmTextField      name='m.postal_adr_code'     label='Postal Code'              ftreq {...fldSm} />
                <FtSpacer h={1} />
                <FtFmDropdownField  name='m.province_id'         label='Province'                 ftreq {...fld} data={lkpProvince} />
                <FtFmSearchField    name='m.region_id'           label='Region'                   ftreq {...fld} data={lkpRegion} />
                <FtFmSearchField    name='m.assembly_id'         label='Assembly'                 ftreq {...fld} data={lkpAssembly} />
                <FtFmTextField      name='m.email'               label='Email'                    ftreq {...fld} />
                <FtFmTextField      name='m.cell_no'             label='Cell No'                  ftreq {...fldSm} />
                <FtSpacer h={1} />
                <FtFmDropdownField  name='m.attendee_type_id'    label='Attend As'                ftreq {...fld} data={lkpAttType} />
                <FtSpacer />
                <FtFmSubmit ss='3:6:3' label='Save and Pay' {...Th.button} />
                <FtSpacer />
            </FtForm>            
        </FtFrame>

        <form id="PayForm" action={payUrl} method="POST">
            <input id="merchant_id"    type="hidden" name="merchant_id"     value="" />
            <input id="merchant_key"   type="hidden" name="merchant_key"    value="" />
            <input id="return_url"     type="hidden" name="return_url"      value="" />
            <input id="cancel_url"     type="hidden" name="cancel_url"      value="" />
            <input id="notify_url"     type="hidden" name="notify_url"      value="" />
            <input id="name_first"     type="hidden" name="name_first"      value="" />
            <input id="name_last"      type="hidden" name="name_last"       value="" />
            <input id="email_address"  type="hidden" name="email_address"   value="" />
            <input id="m_payment_id"   type="hidden" name="m_payment_id"    value="" />
            <input id="amount"         type="hidden" name="amount"          value="" />
            <input id="item_name"      type="hidden" name="item_name"       value="" />
            <input id="signature"      type="hidden" name="signature"       value="" />
        </form>

    </FtCanvas>);

    //====================================================================
    // event handlers

    function onLoad(): void
    {
        //- load lookups -----
        setBusy( true );
        FtRapi.callList( 'assemblyLookup', {
            success: ( result, data ) => {
                setLkpAssembly( data[0] );
                setLkpRegion(   data[1] );

                FtRapi.callList( 'basicLookups', {
                    success: ( result, data ) => {
                        setBusy( false );
                        setLkpTitle(     data[0] );
                        setLkpProvince(  data[1] );
                        setLkpContact(   data[2] );
                        setLkpPayMethod( data[3] );
                        setLkpAttType(   data[4] );
                        setLkpRegStatus( data[5] );
                        //- catch success url -----
                        handlePaySuccess();
                    },
                    error: ( result ) => ftPopupMessage( setPopup, "Error", result.message, { onClose: () => {} } ),
                });        
            },
            error: ( result ) => ftPopupMessage( setPopup, "Error", result.message, { onClose: () => {} } ),
        });
    }

    function handlePaySuccess(): void
    {
        if ( !url?.parms.hasOwnProperty( 'r' ) || url.parms.r != 'paymentSuccess' || !url?.parms.hasOwnProperty( 'rsaid_no' ) ) return;
        mdlAttendee.rsaid_no = url.parms.rsaid_no;
        onIdCheck();
    }

    function onIdCheck(): void
    {
        setBusy( true );
        FtRapi.callRead( 'confRegisterCheck', 0, {
            urlParms: { 'rsaid_no': mdlAttendee?.rsaid_no ?? '' } as any,
            success: ( result, data ) => {
                setBusy( false );
                const statusId = data[0] as Number;
                if ( data.length > 1 )
                    setMdlAttendee( data[1] );
                switch ( statusId ) {
                    case ConfRegisterStatus.CRS_NOT_REGISTERED: setMode( 3 ); break;
                    case ConfRegisterStatus.CRS_REGISTERED_NOTPAID: setMode( 2 ); break;
                    case ConfRegisterStatus.CRS_REGISTERED_AND_PAID: setMode( 1 ); break;
                }
            },
            error: ( { message } ) => ftPopupMessage( setPopup, "Error", message, { onClose: () => setMode( 0 ) } ),
            complete: () => setBusy( false ),
        });
    }

    function onResend(): void
    {
        setBusy( true );
        FtRapi.callRead( 'confResendLetter', 0, {
            urlParms: { 'rsaid_no': mdlAttendee?.rsaid_no ?? '' } as any,
            success: ( result, data ) => {
                setBusy( false );
                ftPopupMessage( setPopup, "Success", result.message, { onClose: () => {} } );
            },
            error: ( { message } ) => ftPopupMessage( setPopup, "Error", message, { onClose: () => setMode( 0 ) } ),
            complete: () => setBusy( false ),
        });
    }    

    function generateSignature( data: any, passphrase: string )
    {
        const lines: Array<string> = [];
        for ( let key in data ) {
            if ( !data.hasOwnProperty( key ) || data[key] === "" ) continue;
            lines.push( `${key}=${encodeURIComponent( data[key].trim() ).replace( /%20/g, "+" )}` );
        }
        lines.push( `passphrase=${encodeURIComponent( passphrase.trim() ).replace( /%20/g, "+" )}` );
        const encoded: string = lines.join( '&' );
        return Ft.md5( encoded );
    };

    function payAttendee( att: Attendee ): void
    {
        //- set busy -----
        setBusy( true );

        //- prepare submit to payfast -----
        const payload: any = {
            merchant_id   : merchantId,
            merchant_key  : merchantKey,
            return_url    : `${Ut.feBaseUrl()}?r=paymentSuccess&rsaid_no=${mdlAttendee.rsaid_no}`,
            cancel_url    : `${Ut.feBaseUrl()}?r=paymentSuccess&rsaid_no=${mdlAttendee.rsaid_no}`,
            notify_url    : `${FtAppInfo.baseUrl}rapi/confPaid?rsaid_no=${mdlAttendee.rsaid_no}`,
            name_first    : mdlAttendee.fullnames,
            name_last     : mdlAttendee.surname,
            email_address : mdlAttendee.email,
            m_payment_id  : mdlAttendee.rsaid_no,
            amount        : payAmnt,
            item_name     : "AFM GBM 2024",
        };
        const signature = generateSignature( payload, passphrase );

        const elems = document.getElementById("PayForm").elements;
        elems.namedItem( "merchant_id"   ).value = payload.merchant_id;
        elems.namedItem( "merchant_key"  ).value = payload.merchant_key;
        elems.namedItem( "return_url"    ).value = payload.return_url;
        elems.namedItem( "cancel_url"    ).value = payload.cancel_url;
        elems.namedItem( "notify_url"    ).value = payload.notify_url;
        elems.namedItem( "name_first"    ).value = payload.name_first;
        elems.namedItem( "name_last"     ).value = payload.name_last;
        elems.namedItem( "email_address" ).value = payload.email_address;
        elems.namedItem( "m_payment_id"  ).value = payload.m_payment_id;
        elems.namedItem( "amount"        ).value = payload.amount;
        elems.namedItem( "item_name"     ).value = payload.item_name;
        elems.namedItem( "signature"     ).value = signature;
       
        document.getElementById("PayForm").submit();
    }

    function onPayNow(): void
    {
        payAttendee( mdlAttendee );
    }

    function onSaveAndPay(): void
    {
        setBusy( true );
        FtRapi.callNew( 'attendeeNewOnline', {
            inRec: mdlAttendee,
            success: ( result, data ) => {
                setBusy( false );
                ftPopupMessage( setPopup, "Success", result.message, { onClose: () => payAttendee( mdlAttendee ) } );
            },
            error: ( result ) => ftPopupMessage( setPopup, "Error", result.message, { onClose: () => {} } ),
        });
    }

}
