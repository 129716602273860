import AttendeeBase from './base/attendeeBase';

//********************************************************************
// Attendee class
// @FTGEN_PROGRESS: busy
export default class Attendee extends AttendeeBase
{
    //====================================================================
    // static data members

    // #FTGEN_CONSTS_A#
    // #FTGEN_CONSTS_Z#

    //====================================================================
    // init
    
    constructor()
    {
        super();
    }
}
