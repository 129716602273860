import RsaProvinceBase from './base/rsaProvinceBase';

//********************************************************************
// RsaProvince class
// @FTGEN_PROGRESS: busy
export default class RsaProvince extends RsaProvinceBase
{
    //====================================================================
    // static data members

    // #FTGEN_CONSTS_A#
    // #FTGEN_CONSTS_Z#

    //====================================================================
    // init
    
    constructor()
    {
        super();
    }
}
