import ReceptionBase from './base/receptionBase';

//********************************************************************
// Reception class
// @FTGEN_PROGRESS: busy
export default class Reception extends ReceptionBase
{
    //====================================================================
    // static data members

    // #FTGEN_CONSTS_A#
    // #FTGEN_CONSTS_Z#

    //====================================================================
    // init
    
    constructor()
    {
        super();
    }
}
