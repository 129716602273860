import PastorFileBase from './base/pastorFileBase';

//********************************************************************
// PastorFile class
// @FTGEN_PROGRESS: busy
export default class PastorFile extends PastorFileBase
{
    //====================================================================
    // static data members

    // #FTGEN_CONSTS_A#
    // #FTGEN_CONSTS_Z#

    //====================================================================
    // init
    
    constructor()
    {
        super();
    }
}
