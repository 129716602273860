import ConferenceBase from './base/conferenceBase';

//********************************************************************
// Conference class
// @FTGEN_PROGRESS: busy
export default class Conference extends ConferenceBase
{
    //====================================================================
    // static data members

    // #FTGEN_CONSTS_A#
    // #FTGEN_CONSTS_Z#

    //====================================================================
    // init
    
    constructor()
    {
        super();
    }
}
