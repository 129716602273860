import AttendeeTypeBase from './base/attendeeTypeBase';

//********************************************************************
// AttendeeType class
// @FTGEN_PROGRESS: busy
export default class AttendeeType extends AttendeeTypeBase
{
    //====================================================================
    // static data members

    // #FTGEN_CONSTS_A#
    static AT_DELPASTOR = 1;
    static AT_DELEGATE = 2;
    static AT_NLFMEMBER = 3;
    static AT_NATDEP = 4;
    static AT_STANCOMMEM = 5;
    static AT_OBSERVER = 6;
    static AT_EMIRITUS = 7;
    // #FTGEN_CONSTS_Z#

    //====================================================================
    // init
    
    constructor()
    {
        super();
    }
}
