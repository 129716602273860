import React, { useState, useEffect } from 'react';
import type { FtPageProps } from '../first/ftTypes';
import Ft from '../first/ft';
import FtRapi from '../first/ftRapi';
import FtFrame from '../first/ftFrame';
import FtCanvas from '../first/ftCanvas';
import FtSpacer from '../first/ftSpacer';
import FtForm from '../first/ftForm';
import FtFmSubmit from '../first/ftFmSubmit';
import FtFmTextField from '../first/ftFmTextField';
import FtFmDateField from '../first/ftFmDateField';
import FtFmCheckboxField from '../first/ftFmCheckboxField';
import { ftBusy } from '../first/ftBusy';
import { ftPopupMessage } from '../first/ftPopupMessage';
import Th from '../general/th';

import AssemblyRegion                     from '../models/assemblyRegion';

//********************************************************************
// PageRegionEdit
// @FTGEN_PROGRESS: done
// ...todo: specify props here...
export default function PageRegionEdit( { navigation, options }: FtPageProps ): JSX.Element
{
    //====================================================================
    // hooks: no conditions here
    
    //- hooks: state -----
    const nav = Ft.useNav( navigation );
    const { regionId } = nav.params ?? { regionId: 0 };    // unpack parameters passed from calling page
    const [ popup      , setPopup        ] = useState( <></> ); // placeholder for any popup message/options
    const [ busy       , setBusy         ] = useState<boolean>( false );
    const [ mdlRegion  , setMdlRegion    ] = useState<AssemblyRegion|null>( null );

    //- hooks: effect/startup -----
    useEffect( onLoad, [] );

    //====================================================================
    // render
    
    if ( busy || Ft.eAny( mdlRegion ) ) return ftBusy( { popup: popup } );

    return (<FtCanvas {...Th.page}>
        {popup}

        <FtFrame text={( regionId == 0 ? 'New' : 'Edit' ) + ' Region'} {...Th.frame}>
            <FtForm {...Th.form}
                models={{ m: mdlRegion }}
                onSubmit={onSave}
            >
                <FtFmTextField     name='m.region_name'    label='Region Name'   {...Th.field} />
                <FtFmTextField     name='m.region_no'      label='Region No'     {...Th.field} />
                <FtFmTextField     name='m.status'         label='Status'        {...Th.field} />
                <FtFmDateField     name='m.file_date'      label='File Date'     {...Th.field} />
                <FtFmCheckboxField name='m.is_filed'       label='Is Filed'      {...Th.field} />
                <FtFmTextField     name='m.move_no'        label='Move No'       {...Th.field} />
                <FtFmCheckboxField name='m.nlf'            label='Nlf'           {...Th.field} />
                <FtSpacer />
                <FtFmSubmit ss='3|2:2' label='Save' {...Th.submit} />
                <FtSpacer />
            </FtForm>
        </FtFrame>

    </FtCanvas>);

    //====================================================================
    // event handlers
    
    function onLoad(): void
    {
        if ( regionId != 0 ) {  // existing record
            FtRapi.callRead( 'assemblyRegionRead', regionId, {
                success: ( result, data ) => setMdlRegion( data[0] ),
                error: ( { message } ) => ftPopupMessage( setPopup, "Error", message, { onClose: nav.pop } ),
            });
        } else {             // new record
            setMdlRegion( new AssemblyRegion() );
        }
    }

    function onSave(): void
    {
        setBusy( true );
        if ( regionId != 0 ) {  // existing record
            FtRapi.callUpdate( 'assemblyRegionUpdate', regionId, {
                inRec: mdlRegion,
                success: ( { message }, data ) => ftPopupMessage( setPopup, "Success", message, { btn: "Close", onClose: nav.pop } ),
                error: ( { message } ) => ftPopupMessage( setPopup, "Error", message ),
                complete: () => setBusy( false ),
            });
        } else {             // new record
            FtRapi.callNew( 'assemblyRegionNew', {
                inRec: mdlRegion,
                success: ( { message }, data ) => {
                    ftPopupMessage( setPopup, "Success", message );
                    setMdlRegion( new AssemblyRegion() );
                },
                error: ( { message } ) => ftPopupMessage( setPopup, "Error", message ),
                complete: () => setBusy( false ),
            });
        }
    }

}
