import React, { useState, useEffect } from 'react';
import type { FtPageProps } from '../first/ftTypes';
import Ft from '../first/ft';
import FtRapi from '../first/ftRapi';
import FtFrame from '../first/ftFrame';
import FtCanvas from '../first/ftCanvas';
import FtSpacer from '../first/ftSpacer';
import FtForm from '../first/ftForm';
import FtFmSubmit from '../first/ftFmSubmit';
import FtFmTextField from '../first/ftFmTextField';
import FtFmPasswordField from '../first/ftFmPasswordField';
import FtFmCheckboxField from '../first/ftFmCheckboxField';
import FtFmDropdownField from '../first/ftFmDropdownField';
import { ftBusy } from '../first/ftBusy';
import { ftPopupMessage } from '../first/ftPopupMessage';
import Th from '../general/th';
import Ut from '../general/ut';

import Userz        from '../models/userz';
import UserAuthRole from '../models/userAuthRole';

//********************************************************************
// PageUserEdit
// @FTGEN_PROGRESS: done
// ...todo: specify props here...
export default function PageUserEdit( { navigation, options }: FtPageProps ): JSX.Element
{
    //====================================================================
    // hooks: no conditions here

    //- hooks: state -----
    const nav = Ft.useNav( navigation );
    const { userId } = nav.params ?? { userId: 0 };    // unpack parameters passed from calling page
    const [ popup         , setPopup        ] = useState( <></> ); // placeholder for any popup message/options
    const [ busy          , setBusy         ] = useState( false );
    const [ mdlUser       , setMdlUser      ] = useState<Userz|null>( null );
    const [ mdlRole       , setMdlRole      ] = useState<UserAuthRole>( new UserAuthRole() );
    const [ mdlPasswords  , setMdlPasswords ] = useState<any>( {
        pass_new     : '',
        pass_confirm : '',
    } );

    //- hooks: effect/startup -----
    useEffect( onLoad, [] );

    //====================================================================
    // render

    if ( busy || Ft.eAny( mdlUser, Ut.lkpUserAuthRoleType ) ) return ftBusy( { popup: popup } );

    return (<FtCanvas {...Th.page}>
        {popup}

        <FtFrame text={(userId == 0 ? 'New' : 'Edit') + ' User'} {...Th.frame}>
            <FtForm {...Th.form}
                models={{
                    m: mdlUser,
                    m2: mdlRole,
                    m3: mdlPasswords,
                }}
                onSubmit={onSave}
            >
                <FtFmTextField      name='m.email'             label='Email'             {...Th.field} ftreq />
                <FtFmTextField      name='m.disp_name'         label='Disp Name'         {...Th.field} ftreq />
                <FtFmPasswordField  name='m3.pass_new'         label='New Password'      {...Th.field} />
                <FtFmPasswordField  name='m3.pass_confirm'     label='Password Confirm'  {...Th.field} />
                <FtFmDropdownField  name='m2.role_id'          label='Role Type'         {...Th.field} data={Ut.lkpUserAuthRoleType} ftreq />
                <FtFmCheckboxField  name='m.active'            label='Active'            {...Th.field} />

                <FtSpacer />
                <FtFmSubmit ss='3:6:3|9:3' label='Save' {...Th.button} />
                <FtSpacer />
            </FtForm>
        </FtFrame>

    </FtCanvas>);

    //====================================================================
    // event handlers

    function onLoad(): void
    {
        //- load lookups -----
        setBusy( true );
        Ut.loadLookups( {
            success: ( result, data ) => setBusy( false ),
            error: ( { message } ) => ftPopupMessage( setPopup, "Error", message, { onClose: nav.pop } ),
        });

        //- init record -----
        if ( userId != 0 ) {  // existing record
            FtRapi.callRead( 'userzRead', userId, {
                success: ( result, data ) => {
                    setMdlUser( data[0] );
                    setMdlRole( data[1] );
                },
                error: ( { message } ) => ftPopupMessage( setPopup, "Error", message, { onClose: nav.pop } ),
            });
        } else {             // new record
            setMdlUser( new Userz() );
            setMdlRole( new UserAuthRole() );
        }
    }

    function onSave(): void
    {
        //- handle password checks -----
        if ( userId == 0 || !Ft.e( mdlPasswords.pass_new ) ) {
            if ( Ft.e( mdlPasswords.pass_new ) || mdlPasswords.pass_new != mdlPasswords.pass_confirm ) {
                ftPopupMessage( setPopup, "Error", "New Password and Confirm empty or do not match, please correct." );
                return;
            }
        }

        //- handle edit -----
        setBusy( true );
        if ( userId != 0 ) {  // existing record
            FtRapi.callUpdate( 'userzUpdate', userId, {
                urlParms: {
                    role_id  : mdlRole.role_id,
                    pass_new : mdlPasswords.pass_new,
                },
                inRec: mdlUser,
                success: ( { message }, data ) => ftPopupMessage( setPopup, "Success", message, { btn: "Close", onClose: nav.pop } ),
                error: ( { message } ) => ftPopupMessage( setPopup, "Error", message ),
                complete: () => setBusy( false ),
            });
        } else {             // new record
            FtRapi.callNew( 'userzNew', {
                urlParms: {
                    role_id  : mdlRole.role_id,
                    pass_new : mdlPasswords.pass_new,
                },
                inRec: mdlUser,
                success: ( { message }, data ) => {
                    ftPopupMessage( setPopup, "Success", message );
                    setMdlUser( new Userz() );
                    setMdlRole( new UserAuthRole() );
                },
                error: ( { message } ) => ftPopupMessage( setPopup, "Error", message ),
                complete: () => setBusy( false ),
            });
        }
    }

}
