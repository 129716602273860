import UserAuthRoleBase from './base/userAuthRoleBase';

//********************************************************************
// UserAuthRole class
// @FTGEN_PROGRESS: busy
export default class UserAuthRole extends UserAuthRoleBase
{
    //====================================================================
    // static data members

    // #FTGEN_CONSTS_A#
    // #FTGEN_CONSTS_Z#

    //====================================================================
    // init
    
    constructor()
    {
        super();
    }
}
