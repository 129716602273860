import React, { useState, useEffect } from 'react';
import type { FtPageProps } from '../first/ftTypes';
import Ft from '../first/ft';
import FtRapi from '../first/ftRapi';
import FtFrame from '../first/ftFrame';
import FtCanvas from '../first/ftCanvas';
import FtSpacer from '../first/ftSpacer';
import FtForm from '../first/ftForm';
import FtFmSubmit from '../first/ftFmSubmit';
import FtFmTextField from '../first/ftFmTextField';
import FtFmTextareaField from '../first/ftFmTextareaField';
import FtFmDateField from '../first/ftFmDateField';
import FtFmDropdownField from '../first/ftFmDropdownField';
import FtFmSearchField from '../first/ftFmSearchField';
import { ftBusy } from '../first/ftBusy';
import { ftPopupMessage } from '../first/ftPopupMessage';
import Th from '../general/th';
import Ut from '../general/ut';

import Justice        from '../models/justice';
import GenLookup      from '../models/genLookup';

//********************************************************************
// PageJusticeEdit
// @FTGEN_PROGRESS: done
// ...todo: specify props here...
export default function PageJusticeEdit( { navigation, options }: FtPageProps ): JSX.Element
{
    //====================================================================
    // hooks: no conditions here

    //- hooks: state -----
    const nav = Ft.useNav( navigation );
    const { justiceId } = nav.params ?? { justiceId: 0 };    // unpack parameters passed from calling page
    const [ popup                  , setPopup                  ] = useState( <></> ); // placeholder for any popup message/options
    const [ busy                   , setBusy                   ] = useState( false );
    const [ mdlJustice             , setMdlJustice             ] = useState<Justice|null>( null );
    const [ lkpPastor              , setLkpPastor              ] = useState<GenLookup[]>( [] );
    const [ lkpUserz               , setLkpUserz               ] = useState<GenLookup[]>( [] );

    //- hooks: effect/startup -----
    useEffect( onLoad, [] );

    //====================================================================
    // render

    if ( busy || Ft.eAny( mdlJustice, Ut.lkpGender, lkpPastor, lkpUserz ) ) return ftBusy( { popup: popup } );

    return (<FtCanvas {...Th.page}>
        {popup}

        <FtFrame text={(justiceId == 0 ? 'New' : 'Edit') + ' Justice Case'} {...Th.frame}>
            <FtForm {...Th.form}
                models={{ m: mdlJustice }}
                onSubmit={onSave}
            >
                <FtFmTextField     name='m.case_no'                            label='Case No'                            {...Th.field} />
                <FtFmTextField     name='m.member_no'                          label='Member No'                          {...Th.field} />

                <FtFmDropdownField name='m.userz_id'                           label='User'                               {...Th.field} data={lkpUserz} />
                <FtFmSearchField   name='m.pastor_id'                          label='Pastor'                             {...Th.field} data={lkpPastor} />

                <FtFmDateField     name='m.entry_date'                         label='Entry Date'                         {...Th.field} />
                <FtFmDateField     name='m.date_resolved'                      label='Date Resolved'                      {...Th.field} />

                <FtFmDateField     name='m.resolutionb_expiry_date'            label='Resolution Expiry Date'             {...Th.field} />
                <FtSpacer />

                <FtFmDropdownField name='m.status_id'                          label='Status'                             {...Th.field} data={Ut.lkpJusticeStatus} />
                <FtFmTextField     name='m.status_name'                        label='Status Name'                        {...Th.field} />

                <FtFmDropdownField name='m.category_id'                        label='Category'                           {...Th.field} data={Ut.lkpJusticeCategory} />
                <FtFmTextField     name='m.category_name'                      label='Category Name'                      {...Th.field} />

                <FtFmTextField     name='m.com_name'                           label='Com Name'                           {...Th.field} />
                <FtFmTextField     name='m.com_physical_adr_1'                 label='Com Physical Adr 1'                 {...Th.field} />

                <FtFmTextField     name='m.com_natid'                          label='Com Natid'                          {...Th.field} />
                <FtFmTextField     name='m.com_physical_adr_2'                 label='Com Physical Adr 2'                 {...Th.field} />

                <FtFmTextField     name='m.com_email'                          label='Com Email'                          {...Th.field} />
                <FtFmTextField     name='m.com_physical_adr_city'              label='Com Physical Adr City'              {...Th.field} />

                <FtFmTextField     name='m.com_affil'                          label='Com Affil'                          {...Th.field} />
                <FtFmTextField     name='m.com_physical_adr_code'              label='Com Physical Adr Code'              {...Th.field} />

                <FtFmDropdownField name='m.com_gender_id'                      label='Com Gender'                         {...Th.field} data={Ut.lkpGender} />
                <FtFmTextField     name='m.com_gender_name'                    label='Com Gender Name'                    {...Th.field} />

                <FtFmTextField     name='m.com_phone'                          label='Com Phone'                          {...Th.field} />
                <FtFmTextField     name='m.com_fax'                            label='Com Fax'                            {...Th.field} />

                <FtFmTextField     name='m.com_cocom'                          label='Com Cocom'                          {...Th.field} />
                <FtSpacer />

                <FtFmTextareaField name='m.description'                        label='Description'                        {...Th.field} lines={5} ss='0:8:1' labSs='2' />
                <FtFmTextareaField name='m.resolution'                         label='Resolution'                         {...Th.field} lines={5} ss='0:8:1' labSs='2' />
                <FtSpacer />

                <FtFmSubmit ss='3|2:2' label='Save' {...Th.submit} />
                <FtSpacer />
            </FtForm>
        </FtFrame>

    </FtCanvas>);

    //====================================================================
    // event handlers

    function onLoad(): void
    {
        //- load lookups -----
        setBusy( true );
        Ut.loadLookups( {
            success: ( result, data ) => setBusy( false ),
            error: ( { message } ) => ftPopupMessage( setPopup, "Error", message, { onClose: nav.pop } ),
        });
        FtRapi.callList( 'pastorLookup', {
            success: ( result, data ) => setLkpPastor( data[0] ),
            error: ( { message } ) => ftPopupMessage( setPopup, "Error", message, { onClose: nav.pop } ),
        });
        FtRapi.callList( 'userzLookup', {
            success: ( result, data ) => setLkpUserz( data[0] ),
            error: ( { message } ) => ftPopupMessage( setPopup, "Error", message, { onClose: nav.pop } ),
        });        

        //- init edit record -----
        if ( justiceId != 0 ) {  // existing record
            FtRapi.callRead( 'justiceRead', justiceId, {
                success: ( result, data ) => setMdlJustice( data[0] ),
                error: ( { message } ) => ftPopupMessage( setPopup, "Error", message, { onClose: nav.pop } ),
            });
        } else {             // new record
            const rec = new Justice();
            setMdlJustice( rec );
        }
    }

    function onSave(): void
    {
        setBusy( true );
        if ( justiceId != 0 ) {  // existing record
            FtRapi.callUpdate( 'justiceUpdate', justiceId, {
                inRec: mdlJustice,
                success: ( { message }, data ) => ftPopupMessage( setPopup, "Success", message, { btn: "Close", onClose: nav.pop } ),
                error: ( { message } ) => ftPopupMessage( setPopup, "Error", message ),
                complete: () => setBusy( false ),
            });
        } else {             // new record
            FtRapi.callNew( 'justiceNew', {
                inRec: mdlJustice,
                success: ( { message }, data ) => {
                    ftPopupMessage( setPopup, "Success", message );
                    const rec = new Justice();
                    setMdlJustice( rec );
                },
                error: ( { message } ) => ftPopupMessage( setPopup, "Error", message ),
                complete: () => setBusy( false ),
            });
        }                
    }
}