import React, { useState, useEffect } from 'react';
import type { FtPageProps, FtDgAction } from '../first/ftTypes';
import Ft from '../first/ft';
import FtRapi from '../first/ftRapi';
import FtFrame from '../first/ftFrame';
import FtCanvas from '../first/ftCanvas';
import FtCon from '../first/ftCon';
import FtSpacer from '../first/ftSpacer';
import FtForm from '../first/ftForm';
import FtText from '../first/ftText';
import FtFmSubmit from '../first/ftFmSubmit';
import FtFmTextField from '../first/ftFmTextField';
import FtList from '../first/ftList';
import FtTextIcons from '../first/ftTextIcons';
import { ftBusy } from '../first/ftBusy';
import { ftPopupMessage } from '../first/ftPopupMessage';
import Th from '../general/th';

import Pastor                             from '../models/pastor';

//********************************************************************
// PagePastorSearchMob
// @FTGEN_PROGRESS: done
// ...todo: specify props here...
export default function PagePastorSearchMob( { navigation, options }: FtPageProps ): JSX.Element
{
    //====================================================================
    // hooks: no conditions here

    //- hooks: state -----
    const nav = Ft.useNav( navigation );
    const [ popup      , setPopup      ] = useState( <></> ); // placeholder for any popup message/options
    const [ busy       , setBusy       ] = useState( false );
    const [ mdlPastor  , setMdlPastor  ] = useState<Pastor|null>( new Pastor() );
    const [ lstPastor  , setLstPastor  ] = useState<Pastor[]>( [] );

    //- hooks: effect/startup -----
    // useEffect( onLoad, [] );

    //====================================================================
    // render

    if ( busy ) return ftBusy( { popup: popup } );

    const lbl = { 
        ss: '4' ,
        bkgClr: '#ffffff',
        pad: '3,6,3,3',
        txtDescr: '18,LO#606060',
        just: 'Z,A',
    };
    const fld = { 
        ss: '8' ,
        bkgClr: '#ffffff',
        pad: '3',
        txtDescr: '16,L#000000', 
    };

    return (<FtCanvas {...Th.page}>
        {popup}

        <FtFrame text='Search Parameters' {...Th.frame}>
            <FtForm {...Th.form}
                models={{ m: mdlPastor }}
                onSubmit={onSearch}
            >
                <FtFmTextField     name='m.member_no'     label='Member No'    {...Th.field} />
                <FtFmTextField     name='m.surname'       label='Surname'      {...Th.field} />
                <FtFmTextField     name='m.first_name'    label='First Name'   {...Th.field} />
                <FtFmTextField     name='m.celno'         label='Celno'        {...Th.field} />
                <FtFmTextField     name='m.phone_home'    label='Phone Home'   {...Th.field} />
                <FtSpacer />
                <FtFmSubmit ss='3:6:3|9:3' label='Search' {...Th.button} />
                <FtSpacer />
            </FtForm>
        </FtFrame>

        <FtFrame text='Search Results' {...Th.frame} ss='12'>
            <FtList {...Th.list}
                data={lstPastor}
                renItem={({ item, index }) => (
                    <FtCon ss='12' key={index}>
                        <FtTextIcons ss='12' {...Th.listItemHead}
                            bkgClr='#888888'
                            text={`${item.surname}, ${item.initials}`}
                            txtDescr='16,WL#ffffff'
                            icDescrR1='24,#ffffff;fa-search'
                            onTapText={() => onTapPastorDetails( item.id )}
                            onTapIconR1={() => onTapPastorDetails( item.id )}
                        />
                        <FtCon ss='12' md='rw'>
                            <FtText {...lbl} text='Member No :' />
                            <FtText {...fld} text={item.member_no} />
                            <FtText {...lbl} text='First Name :' />
                            <FtText {...fld} text={item.first_name} />
                            <FtText {...lbl} text='Phone Home :' />
                            <FtText {...fld} text={item.phone_home} />
                            <FtText {...lbl} text='Celno :' />
                            <FtText {...fld} text={item.celno} />
                        </FtCon>
                    </FtCon>
                )}
            />
        </FtFrame>

    </FtCanvas>);

    //====================================================================
    // event handlers

    function onSearch(): void
    {
        setBusy( true );
        setLstPastor( [] );
        FtRapi.callList( 'pastorSearch', {
            urlParms: {
                prm_member_no     : mdlPastor?.member_no,
                prm_surname       : mdlPastor?.surname,
                prm_first_name    : mdlPastor?.first_name,
                prm_assembly_id   : mdlPastor?.assembly_id,
                prm_region_id     : mdlPastor?.region_id,
                prm_pastor_status : mdlPastor?.pastor_status,
            },
            success: ( result, data ) => setLstPastor( data[0] ),
            error: ( { message } ) => ftPopupMessage( setPopup, "Error", message ),
            complete: () => setBusy( false ),
        });
    }

    function onTapPastorDetails( pastorId: Number ): void
    {
        nav.push( 'PastorDetailMob', { pastorId: pastorId } );
    }
}
