import MaritalStatusBase from './base/maritalStatusBase';

//********************************************************************
// MaritalStatus class
// @FTGEN_PROGRESS: busy
export default class MaritalStatus extends MaritalStatusBase
{
    //====================================================================
    // static data members

    // #FTGEN_CONSTS_A#
    static MS_SINGLE = 1;
    static MS_MARRIED = 2;
    static MS_WIDOWER = 3;
    static MS_WIDOW = 4;
    static MS_DIVORCED = 5;
    static MS_UNKNOWN = 6;
    // #FTGEN_CONSTS_Z#

    //====================================================================
    // init
    
    constructor()
    {
        super();
    }
}
