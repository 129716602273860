import React, { useState, useEffect } from 'react';
import type { FtPageProps } from '../first/ftTypes';
import Ft from '../first/ft';
import FtRapi from '../first/ftRapi';
import FtFrame from '../first/ftFrame';
import FtCanvas from '../first/ftCanvas';
import FtSpacer from '../first/ftSpacer';
import FtForm from '../first/ftForm';
import FtText from '../first/ftText';
import FtFmSubmit from '../first/ftFmSubmit';
import FtFmTextField from '../first/ftFmTextField';
import FtFmTextareaField from '../first/ftFmTextareaField';
import FtFmDecimalField from '../first/ftFmDecimalField';
import FtFmDateField from '../first/ftFmDateField';
import { ftBusy } from '../first/ftBusy';
import { ftPopupMessage } from '../first/ftPopupMessage';
import Th from '../general/th';

import Assembly                           from '../models/assembly';
import AssemblyProperty                   from '../models/assemblyProperty';

//********************************************************************
// PagePropertyEdit
// @FTGEN_PROGRESS: done
// ...todo: specify props here...
export default function PagePropertyEdit( { navigation, options }: FtPageProps ): JSX.Element
{
    //====================================================================
    // hooks: no conditions here

    //- hooks: state -----
    const nav = Ft.useNav( navigation );
    const { assemblyId, propertyId } = nav.params ?? { assemblyId: 0, propertyId: 0 };    // unpack parameters passed from calling page
    const [ popup        , setPopup        ] = useState( <></> ); // placeholder for any popup message/options
    const [ busy         , setBusy         ] = useState( false );
    const [ pnAssembly   , setPnAssembly   ] = useState<Assembly|null>( null );
    const [ mdlProperty  , setMdlProperty  ] = useState<AssemblyProperty|null>( null );

    //- hooks: effect/startup -----
    useEffect( onLoad, [] );

    //====================================================================
    // render

    if ( busy || Ft.eAny( mdlProperty, pnAssembly ) ) return ftBusy( { popup: popup } );

    return (<FtCanvas {...Th.page}>
        {popup}

        <FtFrame text="Assembly" {...Th.frame} ss='12|12|12|10|6'>
            <FtText ss="12" text={`Name : ${pnAssembly?.assembly_name}`} {...Th.textLg} />
            <FtText ss="12" text={`Number : ${pnAssembly?.assembly_no}`} {...Th.textLg} />
        </FtFrame>

        <FtFrame text={(propertyId == 0 ? 'New' : 'Edit') + ' Assembly Property'} {...Th.frame}>
            <FtForm {...Th.form}
                models={{ m: mdlProperty }}
                onSubmit={onSave}
            >
                <FtFmTextField     name='m.physical_adr_1'                     label='Physical Address * '            req {...Th.field} />
                <FtFmTextField     name='m.erf_no'                             label='Erf No * '                      req {...Th.field} />
                <FtFmTextField     name='m.physical_adr_2'                     label=''                                   {...Th.field} />
                <FtFmTextField     name='m.owner'                              label='Owner'                              {...Th.field} />
                <FtFmTextField     name='m.physical_adr_area'                  label='Area'                               {...Th.field} />
                <FtFmDateField     name='m.purch_date'                         label='Purch Date'                         {...Th.field} />
                <FtFmTextField     name='m.physical_adr_region'                label='Region'                             {...Th.field} />
                <FtFmDecimalField  name='m.purch_value'                        label='Purch Value'                        {...Th.field} />
                <FtFmTextField     name='m.physical_adr_city'                  label='City'                               {...Th.field} />
                <FtSpacer />
                <FtFmTextField     name='m.physical_adr_country'               label='Country'                            {...Th.field} />
                <FtFmTextField     name='m.email'                              label='Email'                              {...Th.field} />
                <FtFmTextField     name='m.phone'                              label='Phone'                              {...Th.field} />
                <FtFmTextField     name='m.cell'                               label='Cell'                               {...Th.field} />
                <FtFmTextareaField name='m.notes'                              label='Notes'                              {...Th.fieldLg} />
                <FtSpacer />

                <FtFmSubmit ss='3|2:2' label='Save' {...Th.submit} />
                <FtSpacer />
            </FtForm>
        </FtFrame>

    </FtCanvas>);

    //====================================================================
    // event handlers

    function onLoad(): void
    {
        setBusy( true );
        if ( propertyId != 0 ) {  // existing record
            FtRapi.callRead( 'assemblyPropertyRead', propertyId, {
                success: ( result, data ) => {
                    setMdlProperty( data[0] );
                    setPnAssembly( data[1] );
                },
                error: ( { message } ) => ftPopupMessage( setPopup, "Error", message, { onClose: nav.pop } ),
                complete: () => setBusy( false ),
            });
        } else {             // new record
            FtRapi.callRead( 'assemblyRead', assemblyId, {
                success: ( result, data ) => {
                    setPnAssembly( data[0] );
                    const rec = new AssemblyProperty();
                    rec.assembly_id = assemblyId;
                    rec.purch_date = Ft.dtTodayAsDate();
                    setMdlProperty( rec );
                },
                error: ( { message } ) => ftPopupMessage( setPopup, "Error", message, { btn: "Close", onClose: () => nav.pop() } ),
                complete: () => setBusy( false ),
            });
        }
    }

    function onSave(): void
    {
        setBusy( true );
        if ( propertyId != 0 ) {  // existing record
            FtRapi.callUpdate( 'assemblyPropertyUpdate', propertyId, {
                inRec: mdlProperty,
                success: ( { message }, data ) => ftPopupMessage( setPopup, "Success", message, { btn: "Close", onClose: nav.pop } ),
                error: ( { message } ) => ftPopupMessage( setPopup, "Error", message ),
                complete: () => setBusy( false ),
            });
        } else {             // new record
            FtRapi.callNew( 'assemblyPropertyNew', {
                inRec: mdlProperty,
                success: ( { message }, data ) => {
                    ftPopupMessage( setPopup, "Success", message );
                    const rec = new AssemblyProperty();
                    rec.assembly_id = assemblyId;
                    rec.purch_date = Ft.dtTodayAsDate();
                    setMdlProperty( rec );
                },
                error: ( { message } ) => ftPopupMessage( setPopup, "Error", message ),
                complete: () => setBusy( false ),
            });
        }
    }
}