import React from 'react';
import { ScrollView, View, ImageSourcePropType, ImageBackground } from 'react-native';
import Ft from './ft';

//********************************************************************
// FtCanvas : react-native-paper View/ScrollView wrapped in FtCon
//
//   CANVAS-PROP  AFFECTS    DATA_TYPE           DESCRIPTION                                                    DEFAULT     VALUES
//   scroll       children   bool                Allow canvas to scroll vertically, ignored for web             true        true/false
//   md           children   string              layout mode, responsive, see below                             -           c/r/ac/ar/w > c=col, r=row, ac=anti-col, ar=anti-row, w=wrap
//   lt           children   string              layout, '<main>,<cross>', responsive, see below                -           main : a/c/z/b/e > a=start, c=center, z=end, b=space-between, e=space-evenly; cross: a/c/z/s > a=start, c=center, z=end, s=stretch
//   bkgClr       self       string              background color                                               -           '#<hex-color>'
//   pad/*<side>  self       int/array/string    Padding descriptor, side = t/l/b/r                             0           <all> | <hor,ver> | <top,right,bottom,left>
//   bor          self       int/array/string    Border  descriptor, all sides only                             0           '<thickness>,<color>;<round>'
//   id           self       string              id attribute to be applied to the outermost View for testing   -           -
//   bkgImg       self       image               background image, must be in assets                            -           <image>
//
export default function FtCanvas(
    {
        scroll = 1,
        md, lt,
        bkgClr,
        pad, padT, padL, padB, padR,
        bor, id,
        bkgImg,
        children
    }:
    {
        scroll?: boolean|number;
        md?: string;
        lt?: string;
        bkgClr?: string;
        pad?: number|number[]|string;
        padT?: number|string;
        padB?: number|string;
        padL?: number|string;
        padR?: number|string;
        bor?: number|number[]|string;
        id?: string;
        bkgImg?: ImageSourcePropType;
        children?: React.ReactNode;
    }
): JSX.Element
{
    //====================================================================
    // hooks: no conditions here

    //====================================================================
    // layout & render

    //- prep container props -----
    let style: any = {
        flex: 1,
        ...Ft.parsePadding( pad, padT, padL, padB, padR ),
        ...Ft.parseBorderDescr( bor ),
    };
    let style2: any = {};
    if ( scroll && !Ft.isWeb() ) {  // provide for ScrollView styling
        style2 = Ft.parseLayout( md, lt );
    } else {                        // put everything inside main View
        style = {
            ...style,
            ...Ft.parseLayout( md, lt ),
        };
    }
    if ( bkgClr ) style.backgroundColor = bkgClr;

    //- don't use ScrollView on web, browser already does this internally, only needed on mobile -----
    return !bkgImg
        ? ( scroll && !Ft.isWeb()
            ? (<View nativeID={id} style={style}><ScrollView contentContainerStyle={style2}>{children}</ScrollView></View>)
            : (<View nativeID={id} style={style}>{children}</View>)
          ) 
        : ( scroll && !Ft.isWeb()
            ? (<ImageBackground nativeID={id} style={style} source={bkgImg}><ScrollView contentContainerStyle={style2}>{children}</ScrollView></ImageBackground>)
            : (<ImageBackground nativeID={id} style={style} source={bkgImg}>{children}</ImageBackground>)
        ); 
}
FtCanvas.ftcompname = "FtCanvas";
