import ContactMethodBase from './base/contactMethodBase';

//********************************************************************
// ContactMethod class
// @FTGEN_PROGRESS: busy
export default class ContactMethod extends ContactMethodBase
{
    //====================================================================
    // static data members

    // #FTGEN_CONSTS_A#
    static CM_SMS = 1;
    static CM_WHATSAPP = 2;
    static CM_EMAIL = 3;
    static CM_POST = 4;
    // #FTGEN_CONSTS_Z#

    //====================================================================
    // init
    
    constructor()
    {
        super();
    }
}
