import PaymentMethodBase from './base/paymentMethodBase';

//********************************************************************
// PaymentMethod class
// @FTGEN_PROGRESS: busy
export default class PaymentMethod extends PaymentMethodBase
{
    //====================================================================
    // static data members

    // #FTGEN_CONSTS_A#
    static PM_NONE = 1;
    static PM_ONLINE = 2;
    static PM_CASH = 3;
    static PM_EFT = 4;
    // #FTGEN_CONSTS_Z#

    //====================================================================
    // init
    
    constructor()
    {
        super();
    }
}
