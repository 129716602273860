import React, { useState, useEffect } from 'react';
import type { FtPageProps, FtDgAction } from '../first/ftTypes';
import Ft from '../first/ft';
import FtRapi from '../first/ftRapi';
import FtFrame from '../first/ftFrame';
import FtCanvas from '../first/ftCanvas';
import FtCon from '../first/ftCon';
import FtSpacer from '../first/ftSpacer';
import FtForm from '../first/ftForm';
import FtText from '../first/ftText';
import FtFmSubmit from '../first/ftFmSubmit';
import FtFmTextField from '../first/ftFmTextField';
import FtButton from '../first/ftButton';
import FtDataGrid from '../first/ftDataGrid';
import { ftBusy } from '../first/ftBusy';
import { ftPopupMessage } from '../first/ftPopupMessage';
import { ftPopupConfirm } from '../first/ftPopupOptions';
import Th from '../general/th';

import Justice         from '../models/justice';
import JusticeNote     from '../models/justiceNote';


//********************************************************************
// PageJusticeNoteSearch
// @FTGEN_PROGRESS: done
// ...todo: specify props here...
export default function PageJusticeNoteSearch( { navigation, options }: FtPageProps ): JSX.Element
{
    //====================================================================
    // hooks: no conditions here

    //- hooks: state -----
    const nav = Ft.useNav( navigation );
    const { justiceId } = nav.params ?? { justiceId: 0 };
    const [ popup      , setPopup      ] = useState( <></> ); // placeholder for any popup message/options
    const [ busy       , setBusy       ] = useState( false );
    const [ mdlNote    , setMdlNote    ] = useState( new JusticeNote() );
    const [ pnJustice  , setPnJustice  ] = useState<Justice|null>( null );
    const [ lstNote    , setLstNote    ] = useState<JusticeNote[]>( [] );

    //- hooks: effect/startup -----
    useEffect( onLoad, [] );

    //====================================================================
    // render

    if ( busy || Ft.eAny( pnJustice ) ) return ftBusy( { popup: popup } );

    return (<FtCanvas {...Th.page}>
        {popup}

        <FtFrame text="Case" {...Th.frame} ss='12|12|12|10|6'>
            <FtText ss="12" text={`Case No : ${pnJustice?.case_no}`}     {...Th.textLg} />
            <FtText ss="12" text={`Member No : ${pnJustice?.member_no}`} {...Th.textLg} />
        </FtFrame>

        <FtCon ss='12|12|12|9' md='rw' lt='z,a' marB={15}>
            <FtButton label="New Note" onTap={onClickNewNote} {...Th.button} />
        </FtCon>

        <FtFrame text='Search Parameters' {...Th.frame}>
            <FtForm {...Th.form}
                models={{ m: mdlNote }}
                onSubmit={onSearch}
            >
                <FtFmTextField     name='m.userz_who'      label='User'            {...Th.field} />
                <FtFmTextField     name='m.description'    label='Description'     {...Th.field} />
                <FtSpacer />
                <FtFmSubmit ss='3:6:3|9:3' label='Search' {...Th.button} />
                <FtSpacer />
            </FtForm>
        </FtFrame>

        <FtFrame text='Search Results' {...Th.frame} ss='12'>
            <FtDataGrid {...Th.grid} pgsize={50}
                data={lstNote}
                cols={[
                    { fld: ''             , hd: 'Edit'         , type: 'act'  , flex: 1, fmt: 'mc-pencil', clr: '#808080', act: onTapNoteEdit },
                    { fld: 'entry_date'   , hd: 'Date'         , type: 'date' , flex: 1, fmt: 'YYYY/MM/DD' },
                    { fld: 'userz_who'    , hd: 'User'         , type: 'txt'  , flex: 1 },
                    { fld: 'description'  , hd: 'Description'  , type: 'txt'  , flex: 5 },
                    { fld: ''             , hd: 'Delete'       , type: 'act'  , flex: 1,  fmt: 'fa-trash', clr: '#808080', act: onTapNoteDelete },
                ]}
            />
        </FtFrame>

    </FtCanvas>);

    //====================================================================
    // event handlers

    function onLoad(): void
    {
        setBusy( true );
        FtRapi.callRead( 'justiceRead', justiceId, {
            success: ( result, data ) => {
                setPnJustice( data[0] );
                onSearch();
            },
            error: ( { message } ) => ftPopupMessage( setPopup, "Error", message, { btn: "Close", onClose: () => nav.pop() } ),
            complete: () => setBusy( false ),
        });
    }

    function onSearch(): void
    {
        setBusy( true );
        setLstNote( [] );
        FtRapi.callList( 'JusticeNoteSearch', {
            urlParms: {
                prm_justice_id  : justiceId,
                prm_userz_who   : mdlNote.userz_who,
                prm_description : mdlNote.description,
            },
            success: ( result, data ) => setLstNote( data[0] ),
            error: ( { message } ) => ftPopupMessage( setPopup, "Error", message ),
            complete: () => setBusy( false ),
        });
    }

    function onTapNoteEdit( { dataId }: FtDgAction ): void
    {
        nav.push( 'JusticeNoteEdit', { justiceId: justiceId, noteId: dataId } );
    }

    function onTapNoteDelete( { dataId }: FtDgAction ): void
    {
        ftPopupConfirm( setPopup, 'Confirmation', "Are you sure you want to delete this record?", {
            btnA: 'Yes', onA: () => {
                setLstNote( [] );
                setBusy( true );
                FtRapi.callDelete( 'JusticeNoteDelete', dataId, {
                    success: ( { message }, data ) => ftPopupMessage( setPopup, "Success", message, { onClose: onSearch } ),
                    error: ( { message } ) => ftPopupMessage( setPopup, "Error", message ),
                    complete: () => setBusy( false ),
                });
            },
            btnB: 'No', onB: () => {}
        });
    }

    function onClickNewNote(): void
    {
        nav.push( 'JusticeNoteEdit', { justiceId: justiceId, noteId: 0 } );
    }
}
