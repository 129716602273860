import React, { useState, useEffect } from 'react';
import type { FtPageProps } from '../first/ftTypes';
import Ft from '../first/ft';
import FtRapi from '../first/ftRapi';
import FtCanvas from '../first/ftCanvas';
import FtSpacer from '../first/ftSpacer';
import FtCon from '../first/ftCon';
import FtFrame from '../first/ftFrame';
import FtForm from '../first/ftForm';
import FtFmSubmit from '../first/ftFmSubmit';
import { ftBusy } from '../first/ftBusy';
import { ftPopupMessage } from '../first/ftPopupMessage';
import FtFmPasswordField from '../first/ftFmPasswordField';
import FtFmEmailField from '../first/ftFmEmailField';
import Th from '../general/th';
import Ut from '../general/ut';

import Userz from '../models/userz';

//********************************************************************
// PageAuthLogin
// @FTGEN_PROGRESS: done
// Description:
// Purpose: allow administrator to log into admin console
// Buttons:
// - Login > check credentials, navigate to Profiles-List page
// - Forgot Password > navigate to Forgot-Password page
export default function PageAuthLogin( { navigation, options }: FtPageProps ): JSX.Element
{
    //====================================================================
    // hooks: no conditions here
    //- hooks: state -----
    const nav = Ft.useNav( navigation );
    // const { parameter } = nav.params ?? { parameter: value };
    const [ popup   , setPopup   ] = useState( <></> );
    const [ busy    , setBusy    ] = useState( false );
    const [ mdlUser , setMdlUser ] = useState( new Userz() );

    useEffect( onLoad, [] );

    //====================================================================
    // render

    if ( busy ) return ftBusy( { popup: popup } );
    
    return (<FtCanvas {...Th.page}>
        {popup}

        <FtFrame text='Please enter login details' {...Th.frame} ss='12|10|8|8|6'>
            <FtForm {...Th.form} ss='12|9|9|9|9' 
                models = {{m:mdlUser}}
                onSubmit = {onTapLogin}
            >
                <FtFmEmailField    {...Th.field}  labSs='12|12|4|4|4' ss='12|12|5|5|5' label='Email'     name='m.username' ftreq />
                <FtFmPasswordField {...Th.field}  labSs='12|12|4|4|4' ss='12|12|5|5|5' label='Password'  name='m.password' ftreq />

                <FtSpacer />
                <FtCon ss='12' md='rw' lt='c,a'>
                    <FtFmSubmit   ss='12|6|4|4|4'     {...Th.button}   label='Login' />
                </FtCon>
                <FtSpacer />
            </FtForm>
        </FtFrame>
    </FtCanvas>);

    //====================================================================
    // event handlers

    function onLoad(): void
    {
        setBusy( true );
        FtRapi.callRead( 'authLoginCheck', 0, {
            success: ( result, data ) => {
                if ( !Ft.e( data[0] ) ) nav.replace( 'Menu' );
            },
            complete: () => Ut.loadUser( () => setBusy( false ) ),
        });
    }

    function onTapLogin(): void
    {
        setBusy( true );
        FtRapi.callNew( 'authLogin', {
            urlParms: { 
                'username': mdlUser.username,
                'password': mdlUser.password
            },
            success: ( { message }, data ) => nav.popAllTo( 'Menu' ),
            error: ( { message } ) => ftPopupMessage( setPopup, "Error", message ),
            complete : () => Ut.loadUser( () => setBusy( false ) ),
        });
    }
}
