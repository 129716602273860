import React, { useState, useEffect } from 'react';
import type { FtPageProps } from '../first/ftTypes';
import Ft from '../first/ft';
import FtRapi from '../first/ftRapi';
import FtFrame from '../first/ftFrame';
import FtCanvas from '../first/ftCanvas';
import FtSpacer from '../first/ftSpacer';
import FtForm from '../first/ftForm';
import FtFmSubmit from '../first/ftFmSubmit';
import FtFmTextField from '../first/ftFmTextField';
import FtFmTextareaField from '../first/ftFmTextareaField';
import FtFmDateField from '../first/ftFmDateField';
import FtFmTimeField from '../first/ftFmTimeField';
import FtFmCheckboxField from '../first/ftFmCheckboxField';
import FtFmDropdownField from '../first/ftFmDropdownField';
import FtFmSearchField from '../first/ftFmSearchField';
import { ftBusy } from '../first/ftBusy';
import { ftPopupMessage } from '../first/ftPopupMessage';
import Th from '../general/th';
import Ut from '../general/ut';

import GenLookup            from '../models/genLookup';
import AdminTask            from '../models/adminTask';

//********************************************************************
// PageTaskEdit
// @FTGEN_PROGRESS: done
// ...todo: specify props here...
export default function PageTaskEdit( { navigation, options }: FtPageProps ): JSX.Element
{
    //====================================================================
    // hooks: no conditions here

    //- hooks: state -----
    const nav = Ft.useNav( navigation );
    const { taskId } = nav.params ?? { taskId: 0 };    // unpack parameters passed from calling page
    const [ popup            , setPopup            ] = useState( <></> ); // placeholder for any popup message/options
    const [ busy             , setBusy             ] = useState( false );
    const [ mdlTask          , setMdlTask          ] = useState<AdminTask|null>( null );
    const [ lkpAssembly      , setLkpAssembly      ] = useState<GenLookup[]>( [] );
    const [ lkpUserz         , setLkpUserz         ] = useState<GenLookup[]>( [] );
    const [ initLkpBasic     , setInitLkpBasic     ] = useState<boolean>( false );
    const [ initLkpAssembly  , setInitLkpAssembly  ] = useState<boolean>( false );
    const [ initLkpUserz     , setInitLkpUserz     ] = useState<boolean>( false );

    //- hooks: effect/startup -----
    useEffect( onLoad, [] );

    //====================================================================
    // render

    if ( busy || Ft.eAny( initLkpBasic, initLkpAssembly, initLkpUserz, mdlTask, lkpAssembly, lkpUserz ) ) return ftBusy( { popup: popup } );

    return (<FtCanvas {...Th.page}>
        {popup}

        <FtFrame text={(taskId == 0 ? 'New' : 'Edit') + ' Admin Task'} {...Th.frame}>
            <FtForm {...Th.form}
                models={{ m: mdlTask }}
                onSubmit={onSave}
            >
                <FtFmDropdownField name='m.responsible_id'    label='Responsible'    {...Th.field} ftreq data={lkpUserz} />
                <FtFmDateField     name='m.due_by'            label='Due Date'       {...Th.field} ftreq />
                <FtFmDropdownField name='m.category_id'       label='Category'       {...Th.field} ftreq data={Ut.lkpTaskCategory} />
                <FtFmTimeField     name='m.due_by'            label='Due Time'       {...Th.field} ftreq />
                <FtSpacer />
                <FtFmTextField     name='m.member_no'         label='Member No'      {...Th.field} />
                <FtFmTextField     name='m.request_cat'       label='Request Cat'    {...Th.field} />
                <FtFmSearchField   name='m.assembly_id'       label='Assembly'       {...Th.field} data={lkpAssembly} />
                <FtFmCheckboxField name='m.is_done'           label='Is Done'        {...Th.field} />
                <FtFmTextareaField name='m.description'       label='Description'    {...Th.fieldLg} ftreq />
                <FtSpacer />
                <FtFmSubmit ss='3|2:2' label='Save' {...Th.submit} />
                <FtSpacer />
            </FtForm>
        </FtFrame>

    </FtCanvas>);

    //====================================================================
    // event handlers

    function onLoad(): void
    {
        //- load lookups -----
        Ut.loadLookups( {
            success: ( result, data ) => setInitLkpBasic( true ),
            error: ( { message } ) => ftPopupMessage( setPopup, "Error", message, { onClose: nav.pop } ),
        });

        FtRapi.callList( 'assemblyLookup', {
            success: ( result, data ) => {
                setLkpAssembly( data[0] );
                setInitLkpAssembly( true );
            },
            error: ( result ) => ftPopupMessage( setPopup, "Error", result.message, { onClose: nav.pop } ),
        });

        FtRapi.callList( 'userzLookup', {
            success: ( result, data ) => {
                setLkpUserz( data[0] );
                setInitLkpUserz( true );
            },
            error: ( result ) => ftPopupMessage( setPopup, "Error", result.message, { onClose: nav.pop } ),
        });

        //- init/load form record -----
        if ( taskId != 0 ) {  // existing record
            FtRapi.callRead( 'adminTaskRead', taskId, {
                success: ( result, data ) => setMdlTask( data[0] ),
                error: ( { message } ) => ftPopupMessage( setPopup, "Error", message, { onClose: nav.pop } ),
            });
        } else {             // new record
            const rec = new AdminTask();
            rec.due_by = Ft.dtmAsDate();
            setMdlTask( rec );
        }
    }

    function onSave(): void
    {
        setBusy( true );
        if ( taskId != 0 ) {  // existing record
            FtRapi.callUpdate( 'adminTaskUpdate', taskId, {
                inRec: mdlTask,
                success: ( { message }, data ) => ftPopupMessage( setPopup, "Success", message, { btn: "Close", onClose: nav.pop } ),
                error: ( { message } ) => ftPopupMessage( setPopup, "Error", message ),
                complete: () => setBusy( false ),
            });
        } else {             // new record
            FtRapi.callNew( 'adminTaskNew', {
                inRec: mdlTask,
                success: ( { message }, data ) => {
                    ftPopupMessage( setPopup, "Success", message );
                    const rec = new AdminTask();
                    rec.due_by = Ft.dtmAsDate();
                    setMdlTask( rec );
                },
                error: ( { message } ) => ftPopupMessage( setPopup, "Error", message ),
                complete: () => setBusy( false ),
            });
        }
    }

}
