import JusticeCategoryBase from './base/justiceCategoryBase';

//********************************************************************
// JusticeCategory class
// @FTGEN_PROGRESS: busy
export default class JusticeCategory extends JusticeCategoryBase
{
    //====================================================================
    // static data members

    // #FTGEN_CONSTS_A#
    static JC_ASSEMBLY = 1;
    static JC_PASTOR = 2;
    static JC_REGION = 3;
    // #FTGEN_CONSTS_Z#

    //====================================================================
    // init
    
    constructor()
    {
        super();
    }
}
