import React, { useState, useEffect } from 'react';
import type { FtPageProps } from '../first/ftTypes';
import Ft from '../first/ft';
import FtRapi from '../first/ftRapi';
import FtFrame from '../first/ftFrame';
import FtCanvas from '../first/ftCanvas';
import FtFmUploadImage from '../first/ftFmUploadImage';
import FtSpacer from '../first/ftSpacer';
import FtForm from '../first/ftForm';
import FtFmTextField from '../first/ftFmTextField';
import FtFmTextareaField from '../first/ftFmTextareaField';
import FtFmIntegerField from '../first/ftFmIntegerField';
import FtFmDateField from '../first/ftFmDateField';
import FtFmTimeField from '../first/ftFmTimeField';
import FtFmCheckboxField from '../first/ftFmCheckboxField';
import FtFmDropdownField from '../first/ftFmDropdownField';
import { ftBusy } from '../first/ftBusy';
import { ftPopupMessage } from '../first/ftPopupMessage';
import Th from '../general/th';
import Ut from '../general/ut';

import GenLookup   from '../models/genLookup';
import Pastor      from '../models/pastor';


//********************************************************************
// PagePastorDetailMob
// @FTGEN_PROGRESS: done
// ...todo: specify props here...
export default function PagePastorDetailMob( { navigation, options }: FtPageProps ): JSX.Element
{
    //====================================================================
    // hooks: no conditions here
    
    //- hooks: state -----
    const nav = Ft.useNav( navigation );
    const { pastorId } = nav.params ?? { pastorId: 0 };    
    const [ popup        , setPopup       ] = useState( <></> ); // placeholder for any popup message/options
    const [ busy         , setBusy        ] = useState( false );
    const [ mdlPastor    , setMdlPastor   ] = useState<Pastor|null>( null );
    const [ lkpAssembly  , setLkpAssembly ] = useState<GenLookup[]>( [] );
    const [ lkpRegion    , setLkpRegion   ] = useState<GenLookup[]>( [] );
    const [ mdlUploads   , setMdlUploads  ] = useState<any>( { photoz_key : null } );    

    //- hooks: effect/startup -----
    useEffect( onLoad, [] );

    //====================================================================
    // render
    
    if ( busy || Ft.eAny( mdlPastor, Ut.lkpGender, lkpAssembly, lkpRegion ) ) return ftBusy( { popup: popup } );

    const ctrlPhoto: any = {
        ss: '2:8',
        pad: '3',
        ext: '0',
    };

    return (<FtCanvas {...Th.page}>
        {popup}

        <FtFrame text='Pastor Details' {...Th.frame}>
            <FtForm {...Th.form}
                models={{ m: mdlPastor, m2: mdlUploads }}
            >
                <FtFmUploadImage   name='m2.photoz_key'                        label='Pastor Photo'                       {...ctrlPhoto} en={0} />

                <FtFmTextField     name='m.surname'                            label='Surname'                            {...Th.field}  en={0} />
                <FtFmTextField     name='m.initials'                           label='Initials'                           {...Th.field}  en={0} />
                <FtFmTextField     name='m.first_name'                         label='First Name'                         {...Th.field}  en={0} />
                <FtFmDropdownField name='m.title_id'                           label='Title'                              {...Th.field}  en={0} data={Ut.lkpPastorTitle} />
                <FtFmDropdownField name='m.gender_id'                          label='Gender'                             {...Th.field}  en={0} data={Ut.lkpGender} />
                <FtFmDropdownField name='m.assembly_id'                        label='Assembly'                           {...Th.field}  en={0} data={lkpAssembly} />
                <FtFmTextField     name='m.idno'                               label='Idno'                               {...Th.field}  en={0} />
                <FtFmTextField     name='m.member_no'                          label='Member No'                          {...Th.field}  en={0} />

                <FtFmTextField     name='m.birth_place'                        label='Birth Place'                        {...Th.field}  en={0} />
                <FtFmTextField     name='m.maiden_name'                        label='Maiden Name'                        {...Th.field}  en={0} />                
                <FtFmTextField     name='m.gender_name'                        label='Gender Name'                        {...Th.field}  en={0} />
                <FtFmDateField     name='m.birth_date'                         label='Birth Date'                         {...Th.field}  en={0} />
                <FtFmTextField     name='m.personal_title'                     label='Personal Title'                     {...Th.field}  en={0} />                
                <FtFmDateField     name='m.death_date'                         label='Death Date'                         {...Th.field}  en={0} />
                <FtFmTextareaField name='m.instruments'                        label='Instruments'                        {...Th.field}  en={0} lines={3} />
                <FtFmTextField     name='m.spouse_member_no'                   label='Spouse Member No'                   {...Th.field}  en={0} />
                <FtFmTextField     name='m.spouse_first_name'                  label='Spouse First Name'                  {...Th.field}  en={0} />
                <FtFmTextField     name='m.spouse_surname'                     label='Spouse Surname'                     {...Th.field}  en={0} />
                <FtFmTextField     name='m.spouse_maiden_name'                 label='Spouse Maiden Name'                 {...Th.field}  en={0} />
                <FtFmTextField     name='m.spouse_initials'                    label='Spouse Initials'                    {...Th.field}  en={0} />
                <FtFmTextField     name='m.spouse_personal_title'              label='Spouse Personal Title'              {...Th.field}  en={0} />
                <FtFmTextField     name='m.spouse_idno'                        label='Spouse Idno'                        {...Th.field}  en={0} />
                <FtFmDateField     name='m.spouse_birth_date'                  label='Spouse Birth Date'                  {...Th.field}  en={0} />
                <FtFmTextField     name='m.spouse_birth_place'                 label='Spouse Birth Place'                 {...Th.field}  en={0} />
                <FtFmDateField     name='m.spouse_death_date'                  label='Spouse Death Date'                  {...Th.field}  en={0} />
                <FtFmTextareaField name='m.spouse_talents'                     label='Spouse Talents'                     {...Th.field}  en={0} lines={3} />
                <FtFmTextField     name='m.phone_home'                         label='Phone Home'                         {...Th.field}  en={0} />
                <FtFmTextField     name='m.fax_home'                           label='Fax Home'                           {...Th.field}  en={0} />
                <FtFmTextField     name='m.phone_work'                         label='Phone Work'                         {...Th.field}  en={0} />
                <FtFmTextField     name='m.postal_adr_1'                       label='Postal Adr 1'                       {...Th.field}  en={0} />
                <FtFmTextField     name='m.postal_adr_2'                       label='Postal Adr 2'                       {...Th.field}  en={0} />
                <FtFmTextField     name='m.postal_adr_city'                    label='Postal Adr City'                    {...Th.field}  en={0} />
                <FtFmTextField     name='m.postal_adr_code'                    label='Postal Adr Code'                    {...Th.field}  en={0} />
                <FtFmTextField     name='m.postal_adr_country'                 label='Postal Adr Country'                 {...Th.field}  en={0} />
                <FtFmTextField     name='m.assembly_name'                      label='Assembly Name'                      {...Th.field}  en={0} />
                <FtFmTextField     name='m.assembly_no'                        label='Assembly No'                        {...Th.field}  en={0} />
                <FtFmTextField     name='m.region_no'                          label='Region No'                          {...Th.field}  en={0} />
                <FtFmTextField     name='m.pastor_status'                      label='Pastor Status'                      {...Th.field}  en={0} />
                <FtFmIntegerField  name='m.children_count'                     label='Children Count'                     {...Th.field}  en={0} />
                <FtFmTextField     name='m.marital_status'                     label='Marital Status'                     {...Th.field}  en={0} />
                <FtFmDateField     name='m.wedding_date'                       label='Wedding Date'                       {...Th.field}  en={0} />
                <FtFmDateField     name='m.fulltime_date'                      label='Fulltime Date'                      {...Th.field}  en={0} />
                <FtFmDateField     name='m.marriage_officiant_date'            label='Marriage Officiant Date'            {...Th.field}  en={0} />
                <FtFmTextField     name='m.q_number'                           label='Q Number'                           {...Th.field}  en={0} />
                <FtFmDateField     name='m.ordained_date'                      label='Ordained Date'                      {...Th.field}  en={0} />
                <FtFmDateField     name='m.legitimize_date'                    label='Legitimize Date'                    {...Th.field}  en={0} />
                <FtFmDateField     name='m.preachers_panel_tv_date'            label='Preachers Panel Tv Date'            {...Th.field}  en={0} />
                <FtFmDateField     name='m.preachers_panel_radio_date'         label='Preachers Panel Radio Date'         {...Th.field}  en={0} />
                <FtFmTextField     name='m.phone_extension'                    label='Phone Extension'                    {...Th.field}  en={0} />
                <FtFmCheckboxField name='m.phone_request_line'                 label='Phone Request Line'                 {...Th.field}  en={0} />
                <FtFmTextareaField name='m.remarks'                            label='Remarks'                            {...Th.field}  en={0} lines={3} />
                <FtFmTextField     name='m.language_preference'                label='Language Preference'                {...Th.field}  en={0} />
                <FtFmTextField     name='m.physical_adr_1'                     label='Physical Adr 1'                     {...Th.field}  en={0} />
                <FtFmTextField     name='m.physical_adr_2'                     label='Physical Adr 2'                     {...Th.field}  en={0} />
                <FtFmTextField     name='m.physical_adr_city'                  label='Physical Adr City'                  {...Th.field}  en={0} />
                <FtFmTextField     name='m.physical_adr_code'                  label='Physical Adr Code'                  {...Th.field}  en={0} />
                <FtFmDateField     name='m.input_date'                         label='Input Date'                         {...Th.field}  en={0} />
                <FtFmTextField     name='m.position_no'                        label='Position No'                        {...Th.field}  en={0} />
                <FtFmTextField     name='m.celno'                              label='Celno'                              {...Th.field}  en={0} />
                <FtFmTextField     name='m.email'                              label='Email'                              {...Th.field}  en={0} />
                <FtFmDateField     name='m.expiry_date'                        label='Expiry Date'                        {...Th.field}  en={0} />
                <FtFmTextareaField name='m.photo'                              label='Photo'                              {...Th.field}  en={0} lines={3} />
                <FtFmDateField     name='m.application_date'                   label='Application Date'                   {...Th.field}  en={0} />
                <FtFmTextareaField name='m.notes'                              label='Notes'                              {...Th.field}  en={0} lines={3} />
                <FtFmTextField     name='m.file_date'                          label='File Date'                          {...Th.field}  en={0} />
                <FtFmCheckboxField name='m.is_filed'                           label='Is Filed'                           {...Th.field}  en={0} />
                <FtFmTextField     name='m.move_no'                            label='Move No'                            {...Th.field}  en={0} />
                <FtFmDateField     name='m.last_updated'                       label='Last Updated'                       {...Th.field}  en={0} />
                <FtFmTimeField     name='m.last_updated'                       label='Last Updated'                       {...Th.field}  en={0} />
                <FtFmDateField     name='m.add_change_date'                    label='Add Change Date'                    {...Th.field}  en={0} />
                <FtFmTimeField     name='m.add_change_date'                    label='Add Change Date'                    {...Th.field}  en={0} />
                <FtFmCheckboxField name='m.preferred_addressee'                label='Preferred Addressee'                {...Th.field}  en={0} />
                <FtFmCheckboxField name='m.ge_comms'                           label='Ge Comms'                           {...Th.field}  en={0} />
                <FtFmDateField     name='m.special_start_date'                 label='Special Start Date'                 {...Th.field}  en={0} />
                <FtFmDateField     name='m.special_end_date'                   label='Special End Date'                   {...Th.field}  en={0} />
                <FtFmTextareaField name='m.special_reason'                     label='Special Reason'                     {...Th.field}  en={0} lines={3} />
                <FtFmTextField     name='m.file_no'                            label='File No'                            {...Th.field}  en={0} />
                <FtFmTextField     name='m.bin'                                label='Bin'                                {...Th.field}  en={0} />
                <FtFmTextField     name='m.seconded_to'                        label='Seconded To'                        {...Th.field}  en={0} />
                <FtFmDateField     name='m.seconded_report_date'               label='Seconded Report Date'               {...Th.field}  en={0} />
                <FtFmDateField     name='m.called_date'                        label='Called Date'                        {...Th.field}  en={0} />
                <FtFmDateField     name='m.accepted_date'                      label='Accepted Date'                      {...Th.field}  en={0} />
                <FtFmDateField     name='m.seconded_date'                      label='Seconded Date'                      {...Th.field}  en={0} />
                <FtFmTextField     name='m.country'                            label='Country'                            {...Th.field}  en={0} />
                <FtFmCheckboxField name='m.justice_case_active'                label='Justice Case Active'                {...Th.field}  en={0} />
                <FtFmTextField     name='m.last_edited'                        label='Last Edited'                        {...Th.field}  en={0} />
                <FtFmTextareaField name='m.field1_text'                        label='Field1 Text'                        {...Th.field}  en={0} lines={3} />
                <FtFmTextareaField name='m.field2_num'                         label='Field2 Num'                         {...Th.field}  en={0} lines={3} />
                <FtFmDateField     name='m.field3_date'                        label='Field3 Date'                        {...Th.field}  en={0} />
                <FtFmTextareaField name='m.field_text2'                        label='Field Text2'                        {...Th.field}  en={0} lines={3} />
                <FtFmTextareaField name='m.field_text3'                        label='Field Text3'                        {...Th.field}  en={0} lines={3} />
                <FtFmTextareaField name='m.field_text4'                        label='Field Text4'                        {...Th.field}  en={0} lines={3} />
                <FtFmTextareaField name='m.field_text5'                        label='Field Text5'                        {...Th.field}  en={0} lines={3} />
                <FtFmTextField     name='m.pastor_own'                         label='Pastor Own'                         {...Th.field}  en={0} />
                <FtFmDropdownField name='m.region_id'                          label='Region'                             {...Th.field}  en={0} data={lkpRegion} />
                <FtFmDropdownField name='m.marital_status_id'                  label='Marital Status'                     {...Th.field}  en={0} data={Ut.lkpMaritalStatus} />
                <FtFmDropdownField name='m.position_id'                        label='Position'                           {...Th.field}  en={0} data={Ut.lkpPastorPosition} />
                <FtSpacer />
            </FtForm>
        </FtFrame>

    </FtCanvas>);

    //====================================================================
    // event handlers

    function onLoad(): void 
    {
        //- load lookups -----
        setBusy( true );
        Ut.loadLookups( {
            success: ( result, data ) => setBusy( false ),
            error: ( { message } ) => ftPopupMessage( setPopup, "Error", message, { onClose: nav.pop } ),
        });        
        FtRapi.callList( 'assemblyLookup', {
            success: ( result, data ) => {
                setLkpAssembly( data[0] );
                setLkpRegion( data[1] );
            },
            error: ( { message } ) => ftPopupMessage( setPopup, "Error", message, { onClose: nav.pop } ),
        });        

        //- init record -----
        if ( pastorId != 0 ) {  // existing record
            FtRapi.callRead( 'pastorRead', pastorId, {
                success: ( result, data ) => {
                    mdlUploads.photoz_key = data[1]?.repo_key;
                    setMdlPastor( data[0] );
                },
                error: ( { message } ) => ftPopupMessage( setPopup, "Error", message, { onClose: nav.pop } ),
            });    
        } else {             // new record
            ftPopupMessage( setPopup, "Error", "Pastor not found, please try again.", { onClose: nav.pop } )
        }        
    }

}
