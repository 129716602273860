import AttendeeTitleBase from './base/attendeeTitleBase';

//********************************************************************
// AttendeeTitle class
// @FTGEN_PROGRESS: busy
export default class AttendeeTitle extends AttendeeTitleBase
{
    //====================================================================
    // static data members

    // #FTGEN_CONSTS_A#
    static AT_PAST = 1;
    static AT_MR = 2;
    static AT_MS = 3;
    static AT_MRS = 4;
    static AT_DR = 5;
    static AT_PROF = 6;
    // #FTGEN_CONSTS_Z#

    //====================================================================
    // init
    
    constructor()
    {
        super();
    }
}
