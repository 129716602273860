import React, { useState, useEffect } from 'react';
import type { FtPageProps } from '../first/ftTypes';
import Ft from '../first/ft';
import FtRapi from '../first/ftRapi';
import FtFrame from '../first/ftFrame';
import FtCanvas from '../first/ftCanvas';
import FtSpacer from '../first/ftSpacer';
import FtForm from '../first/ftForm';
import FtText from '../first/ftText';
import FtFmSubmit from '../first/ftFmSubmit';
import FtFmTextareaField from '../first/ftFmTextareaField';
import FtFmDateField from '../first/ftFmDateField';
import FtFmDropdownField from '../first/ftFmDropdownField';
import { ftBusy } from '../first/ftBusy';
import { ftPopupMessage } from '../first/ftPopupMessage';
import Th from '../general/th';

import Justice        from '../models/justice';
import JusticeNote    from '../models/justiceNote';
import Userz          from '../models/userz';

//********************************************************************
// PageJusticeNoteEdit
// @FTGEN_PROGRESS: done
// ...todo: specify props here...
export default function PageJusticeNoteEdit( { navigation, options }: FtPageProps ): JSX.Element
{
    //====================================================================
    // hooks: no conditions here

    //- hooks: state -----
    const nav = Ft.useNav( navigation );
    const { justiceId, noteId } = nav.params ?? { justiceId: 0, noteId: 0 };    // unpack parameters passed from calling page
    const [ popup         , setPopup        ] = useState( <></> ); // placeholder for any popup message/options
    const [ busy          , setBusy         ] = useState( false );
    const [ mdlNote       , setMdlNote      ] = useState<JusticeNote|null>( null );
    const [ pnJustice     , setPnJustice    ] = useState<Justice|null>( null );
    const [ lkpUserz      , setLkpUserz     ] = useState( [] as Userz[] );

    //- hooks: effect/startup -----
    useEffect( onLoad, [] );

    //====================================================================
    // render

    if ( busy || Ft.eAny( mdlNote, pnJustice ) ) return ftBusy( { popup: popup } );

    return (<FtCanvas {...Th.page}>
        {popup}

        <FtFrame text="Case" {...Th.frame} ss='12|12|12|10|6'>
            <FtText ss="12" text={`Case No : ${pnJustice?.case_no}`}     {...Th.textLg} />
            <FtText ss="12" text={`Member No : ${pnJustice?.member_no}`} {...Th.textLg} />
        </FtFrame>

        <FtFrame text={(noteId == 0 ? 'New' : 'Edit') + ' Justice Case Note'} {...Th.frame}>
            <FtForm {...Th.form}
                models={{ m: mdlNote }}
                onSubmit={onSave}
            >
                <FtFmDropdownField name='m.userz_id'       label='User'           {...Th.field} data={lkpUserz} />
                <FtFmDateField     name='m.entry_date'     label='Entry Date'     {...Th.field} />

                <FtFmTextareaField name='m.description'    label='Description'    {...Th.field} lines={5} />
                <FtSpacer />

                <FtFmSubmit ss='3|2:2' label='Save' {...Th.submit} />
                <FtSpacer />
            </FtForm>
        </FtFrame>

    </FtCanvas>);

    //====================================================================
    // event handlers

    function onLoad(): void
    {
        setBusy( true );
        FtRapi.callList( 'userzLookup', {
            success: ( result, data ) => {
                setLkpUserz( data[0] );
                if ( noteId != 0 ) {  // existing record
                    FtRapi.callRead( 'justiceNoteRead', noteId, {
                        success: ( result, data ) => {
                            setMdlNote( data[0] );
                            setPnJustice( data[1] );
                        },
                        error: ( { message } ) => ftPopupMessage( setPopup, "Error", message, { onClose: nav.pop } ),
                        complete: () => setBusy( false ),
                    });
                } else {             // new record
                    FtRapi.callRead( 'justiceRead', justiceId, {
                        success: ( result, data ) => {
                            setPnJustice( data[0] );
                            const rec = new JusticeNote();
                            rec.justice_id = justiceId;
                            rec.justice_case_no = data[0].case_no;
                            rec.entry_date = Ft.dtTodayAsDate();
                            setMdlNote( rec );
                        },
                        error: ( { message } ) => ftPopupMessage( setPopup, "Error", message, { onClose: nav.pop } ),
                        complete: () => setBusy( false ),
                    });
                }
            },
            error: ( { message } ) => ftPopupMessage( setPopup, "Error", message, { onClose: nav.pop } ), // Ft.dirs( "ERROR:", result );
            complete: () => setBusy( false ),
        });


    }

    function onSave(): void
    {
        setBusy( true );
        if ( noteId != 0 ) {  // existing record
            FtRapi.callUpdate( 'justiceNoteUpdate', noteId, {
                inRec: mdlNote,
                success: ( { message }, data ) => ftPopupMessage( setPopup, "Success", message, { btn: "Close", onClose: nav.pop } ),
                error: ( { message } ) => ftPopupMessage( setPopup, "Error", message ),
                complete: () => setBusy( false ),
            });
        } else {             // new record
            FtRapi.callNew( 'justiceNoteNew', {
                inRec: mdlNote,
                success: ( { message }, data ) => {
                    ftPopupMessage( setPopup, "Success", message );
                    const rec = new JusticeNote();
                    rec.justice_id = justiceId;
                    rec.justice_case_no = pnJustice!.case_no;
                    rec.entry_date = Ft.dtTodayAsDate();
                    setMdlNote( rec );
                },
                error: ( { message } ) => ftPopupMessage( setPopup, "Error", message ),
                complete: () => setBusy( false ),
            });
        }            
    }
}