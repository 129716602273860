import JusticeStatusBase from './base/justiceStatusBase';

//********************************************************************
// JusticeStatus class
// @FTGEN_PROGRESS: busy
export default class JusticeStatus extends JusticeStatusBase
{
    //====================================================================
    // static data members

    // #FTGEN_CONSTS_A#
    static JS_ACTIVE = 1;
    static JS_CLOSED = 2;
    // #FTGEN_CONSTS_Z#

    //====================================================================
    // init
    
    constructor()
    {
        super();
    }
}
