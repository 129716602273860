import React, { useState } from 'react';
import type { FtPageProps, FtDgAction } from '../first/ftTypes';
import Ft from '../first/ft';
import FtRapi from '../first/ftRapi';
import FtFrame from '../first/ftFrame';
import FtCanvas from '../first/ftCanvas';
import FtCon from '../first/ftCon';
import FtSpacer from '../first/ftSpacer';
import FtForm from '../first/ftForm';
import FtFmSubmit from '../first/ftFmSubmit';
import FtFmTextField from '../first/ftFmTextField';
import FtButton from '../first/ftButton';
import FtDataGrid from '../first/ftDataGrid';
import { ftBusy } from '../first/ftBusy';
import { ftPopupMessage } from '../first/ftPopupMessage';
import { ftPopupConfirm } from '../first/ftPopupOptions';
import Th from '../general/th';

import Userz  from '../models/userz';

//********************************************************************
// PageUserSearch
// @FTGEN_PROGRESS: done
// ...todo: specify props here...
export default function PageUserSearch( { navigation, options }: FtPageProps ): JSX.Element
{
    //====================================================================
    // hooks: no conditions here
    
    //- hooks: state -----
    const nav = Ft.useNav( navigation );
    const [ popup     , setPopup     ] = useState( <></> ); // placeholder for any popup message/options
    const [ busy      , setBusy      ] = useState( false );
    const [ mdlUserz  , setMdlUserz  ] = useState( new Userz() );
    const [ lstUserz  , setLstUserz  ] = useState<Userz[]>( [] );

    //- hooks: effect/startup -----
    // useEffect( onLoad, [] );

    //====================================================================
    // render
    
    if ( busy ) return ftBusy( { popup: popup } );

    return (<FtCanvas {...Th.page}>
        {popup}

        <FtCon ss='12|12|12|9' md='rw' lt='z,a' marB={15}>
            <FtButton label="New User" onTap={onClickNewUser} {...Th.button} />
        </FtCon>
                
        <FtFrame text='Search Parameters' {...Th.frame}>
            <FtForm {...Th.form}
                models={{ m: mdlUserz }}
                onSubmit={onSearch}
            >
                <FtFmTextField     name='m.disp_name'     label='Display Name'   {...Th.field} />
                <FtFmTextField     name='m.email'         label='Email'          {...Th.field} />
                <FtSpacer />
                <FtFmSubmit ss='3:6:3|9:3' label='Search' {...Th.button} />
                <FtSpacer />
            </FtForm>
        </FtFrame>

        <FtFrame text='Search Results' {...Th.frame} ss='12'>
            <FtDataGrid {...Th.grid} pgsize={50}
                data={lstUserz}
                cols={[
                    { fld: ''              , hd: 'Edit'         , type: 'act'  , flex: 1, fmt: 'mc-pencil', clr: '#808080', act: onTapUserzEdit },
                    { fld: 'disp_name'     , hd: 'Disp Name'    , type: 'txt'  , flex: 3 },
                    { fld: 'email'         , hd: 'Email'        , type: 'txt'  , flex: 3 },
                    { fld: 'active'        , hd: 'Active'       , type: 'bool' , flex: 1, clr: '#808080' },
                    { fld: 'pass_reset'    , hd: 'Pass Reset'   , type: 'bool' , flex: 1, clr: '#808080' },
                    { fld: 'last_login'    , hd: 'Last Login'   , type: 'dtm'  , flex: 3, fmt: 'YYYY/MM/DD HH:mm' },
                    { fld: ''              , hd: 'Delete'       , type: 'act'  , flex: 1, fmt: 'fa-trash', clr: '#808080', act: onTapUserzDelete },
                ]}
            />
        </FtFrame>
        

    </FtCanvas>);

    //====================================================================
    // event handlers

    function onSearch(): void
    {
        setBusy( true );
        setLstUserz( [] );
        FtRapi.callList( 'userzSearch', {
            urlParms: {
                prm_disp_name : mdlUserz.disp_name,
                prm_email     : mdlUserz.email,
            },
            success: ( result, data ) => setLstUserz( data[0] ),
            error: ( { message } ) => ftPopupMessage( setPopup, "Error", message ),
            complete: () => setBusy( false ),
        });
    }

    function onTapUserzEdit( { dataId }: FtDgAction ): void
    {
        nav.push( 'UserEdit', { userId: dataId } );
    }

    function onTapUserzDelete( { dataId }: FtDgAction ): void
    {
        ftPopupConfirm( setPopup, 'Confirmation', "Are you sure you want to delete this record?", {
            btnA: 'Yes', onA: () => {
                setBusy( true );
                setLstUserz( [] );
                FtRapi.callDelete( 'userzDelete', dataId, {
                    success: ( { message }, data ) => ftPopupMessage( setPopup, "Success", message, { onClose: onSearch } ),
                    error: ( { message } ) => ftPopupMessage( setPopup, "Error", message ),
                    complete: () => setBusy( false ),
                });
            },
            btnB: 'No', onB: () => {}
        });
    }

    function onClickNewUser(): void
    {
        nav.push( 'UserEdit', { userId: 0 } );
    }

}
