import React, { useState } from 'react';
import type { FtPageProps, FtDgAction } from '../first/ftTypes';
import Ft from '../first/ft';
import FtRapi from '../first/ftRapi';
import FtFrame from '../first/ftFrame';
import FtCanvas from '../first/ftCanvas';
import FtCon from '../first/ftCon';
import FtSpacer from '../first/ftSpacer';
import FtForm from '../first/ftForm';
import FtFmSubmit from '../first/ftFmSubmit';
import FtFmTextField from '../first/ftFmTextField';
import FtDataGrid from '../first/ftDataGrid';
import FtButton from '../first/ftButton';
import { ftBusy } from '../first/ftBusy';
import { ftPopupMessage } from '../first/ftPopupMessage';
import Th from '../general/th';

import Justice  from '../models/justice';

//********************************************************************
// PageJusticeSearch
// @FTGEN_PROGRESS: done
// ...todo: specify props here...
export default function PageJusticeSearch( { navigation, options }: FtPageProps ): JSX.Element
{
    //====================================================================
    // hooks: no conditions here
    
    //- hooks: state -----
    const nav = Ft.useNav( navigation );
    const [ popup       , setPopup       ] = useState( <></> ); // placeholder for any popup message/options
    const [ busy        , setBusy        ] = useState( false );
    const [ mdlJustice  , setMdlJustice  ] = useState( new Justice() );
    const [ lstJustice  , setLstJustice  ] = useState<Justice[]>( [] );

    //- hooks: effect/startup -----
    // useEffect( onLoad, [] );


    //====================================================================
    // render
    
    if ( busy ) return ftBusy( { popup: popup } );

    return (<FtCanvas {...Th.page}>
        {popup}

        <FtCon ss='12|12|12|10|8' md='rw' lt='z,a' marB={15}>
            <FtButton label="New Justice Case" onTap={onClickNewJusticeCase} {...Th.button} />
        </FtCon>
                
        <FtFrame text='Search Parameters' {...Th.frame} ss='12|12|12|10|8'>
            <FtForm {...Th.form}
                models={{ m: mdlJustice }}
                onSubmit={onSearch}
            >
                <FtFmTextField     name='m.case_no'    label='Case No'    {...Th.field} />
                <FtFmTextField     name='m.member_no'  label='Member No'  {...Th.field} />
                <FtSpacer />
                <FtFmSubmit ss='3:6:3|9:3' label='Search' {...Th.button} />
                <FtSpacer />
            </FtForm>
        </FtFrame>

        <FtFrame text='Search Results' {...Th.frame} ss='12'>
            <FtDataGrid {...Th.grid} pgsize={50}
                data={lstJustice}
                cols={[
                    // { fld: ''              , hd: 'Edit'         , type: 'act'  , flex: 1, fmt: 'mc-pencil'    , clr: '#808080', act: onTapJusticeEdit },
                    { fld: ''              , hd: 'Notes'        , type: 'act'  , flex: 1, fmt: 'ii-documents' , clr: '#808080', act: onTapJusticeNotes },
                    { fld: ''              , hd: 'Files'        , type: 'act'  , flex: 1, fmt: 'mc-paperclip' , clr: '#808080', act: onTapJusticeFiles },
                    { fld: 'entry_date'    , hd: 'Entry Date'   , type: 'date' , flex: 2, fmt: 'YYYY/MM/DD' },
                    { fld: 'case_no'       , hd: 'Case No'      , type: 'txt'  , flex: 3 },
                    { fld: 'member_no'     , hd: 'Member No'    , type: 'txt'  , flex: 3 },
                    { fld: 'com_name'      , hd: 'Com.Name'     , type: 'txt'  , flex: 3 },
                ]}
            />
        </FtFrame>

    </FtCanvas>);

    //====================================================================
    // event handlers

    function onSearch(): void
    {
        setBusy( true );
        setLstJustice( [] );
        FtRapi.callList( 'JusticeSearch', {
            urlParms: {
                prm_case_no   : mdlJustice.case_no, 
                prm_member_no : mdlJustice.member_no,   
            },
            success: ( result, data ) => setLstJustice( data[0] ),
            error: ( { message } ) => ftPopupMessage( setPopup, "Error", message ),
            complete: () => setBusy( false ),
        });
    }

    function onTapJusticeEdit( { dataId }: FtDgAction ): void
    {
        nav.push( 'JusticeEdit', { justiceId: dataId } );
    }

    function onTapJusticeNotes( { dataId }: FtDgAction ): void
    {
        nav.push( 'JusticeNoteSearch', { justiceId: dataId } );
    }

    function onTapJusticeFiles( { dataId }: FtDgAction ): void
    {
        nav.push( 'JusticeFileSearch', { justiceId: dataId } );
    }

    function onClickNewJusticeCase(): void
    {
        nav.push( 'JusticeEdit', { justiceId: 0 } );
    }
}
