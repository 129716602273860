import JusticeNoteBase from './base/justiceNoteBase';

//********************************************************************
// JusticeNote class
// @FTGEN_PROGRESS: busy
export default class JusticeNote extends JusticeNoteBase
{
    //====================================================================
    // static data members

    // #FTGEN_CONSTS_A#
    // #FTGEN_CONSTS_Z#

    //====================================================================
    // init
    
    constructor()
    {
        super();
    }
}
