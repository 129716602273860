import AdminTaskCategoryBase from './base/adminTaskCategoryBase';

//********************************************************************
// AdminTaskCategory class
// @FTGEN_PROGRESS: busy
export default class AdminTaskCategory extends AdminTaskCategoryBase
{
    //====================================================================
    // static data members

    // #FTGEN_CONSTS_A#
    static ATC_NOT_URGENT = 1;
    static ATC_FAIRLY = 2;
    static ATC_URGENT = 3;
    // #FTGEN_CONSTS_Z#

    //====================================================================
    // init
    
    constructor()
    {
        super();
    }
}
