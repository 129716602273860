import Ft from '../first/ft';
import FtRapi from '../first/ftRapi';
import FtAppInfo from '../first/ftAppInfo';
import FtRapiResult from '../first/ftRapiResult';
import type { FtRapiCallArgs } from '../first/ftTypes';

import UserAuthRoleType from '../models/userAuthRoleType';
import GenLookup        from '../models/genLookup'

//********************************************************************
// App wide utilities
export default class Ut {

    //====================================================================
    // properties

    //- server info -----
    static svrVer: string = "";

    //- logged-in user -----
    static user_id: number = 0;
    static display_name: string = "";
    static role_id: number = 0;                     // user_auth_role_type id
    static user_roles: number[] = [];               // roles user is authorized for

    //- cached lookups -----
    static lkpAttendeeTitle     : GenLookup[]|null = null;
    static lkpProvince          : GenLookup[]|null = null;
    static lkpContactMethod     : GenLookup[]|null = null;
    static lkpPayMethod         : GenLookup[]|null = null;
    static lkpAttendeeType      : GenLookup[]|null = null;
    static lkpRegisterStatus    : GenLookup[]|null = null;
    static lkpArcRepoMediaType  : GenLookup[]|null = null;
    static lkpAssemblyType      : GenLookup[]|null = null;
    static lkpMaritalStatus     : GenLookup[]|null = null;
    static lkpGender            : GenLookup[]|null = null;
    static lkpTaskCategory      : GenLookup[]|null = null;
    static lkpJusticeStatus     : GenLookup[]|null = null;
    static lkpJusticeCategory   : GenLookup[]|null = null;
    static lkpPastorStatus      : GenLookup[]|null = null;
    static lkpPastorTitle       : GenLookup[]|null = null;
    static lkpPastorPosition    : GenLookup[]|null = null;
    static lkpUserAuthRoleType  : GenLookup[]|null = null;
    static lkpRetireType        : GenLookup[]|null = null;

    //- frontend urls -----
    static feBaseUrlDebug: string = 'http://localhost:19006/';
    static feBaseUrlRelease: string = 'https://afminfosystem.azurewebsites.net/frontend/index.html';

    //- sharepoint urls -----
    static pastorFolderLink = "https://afmagsorg.sharepoint.com/:f:/r/sites/AFMDEVELOPMENT/Shared%20Documents/DEVELOPMENT/Comms/Pastors";
    static assemblyFolderLink = "https://afmagsorg.sharepoint.com/:f:/r/sites/AFMDEVELOPMENT/Shared%20Documents/DEVELOPMENT/Comms";

    //- user role constants -----
    static RoleT: any = {
        SYSTEMADMIN               : UserAuthRoleType.UART_SYSTEMADMIN,
        NOB                       : UserAuthRoleType.UART_NOB,
        ADMINISTRATION            : UserAuthRoleType.UART_ADMINISTRATION,
        ADMINJUSTICE              : UserAuthRoleType.UART_ADMINJUSTICE,
        NLF                       : UserAuthRoleType.UART_NLF,
        REGIONAL_SECRETARY        : UserAuthRoleType.UART_REGIONAL_SECRETARY,
        REGIONAL_TREASURER        : UserAuthRoleType.UART_REGIONAL_TREASURER,
        ADMIN_CLERK               : UserAuthRoleType.UART_ADMIN_CLERK,
        CONF_SUPERVISOR           : UserAuthRoleType.UART_CONF_SUPERVISOR,
        CONF_RECEPTION_SCANNING   : UserAuthRoleType.UART_CONF_RECEPTION_SCANNING,
        CONF_GOODYBAG_SCANNING    : UserAuthRoleType.UART_CONF_GOODYBAG_SCANNING,
        CONF_DISPLAY_STATS        : UserAuthRoleType.UART_CONF_DISPLAY_STATS,
        VOTING_MANAGER            : UserAuthRoleType.UART_VOTING_MANAGER,
        VOTING_CAPTURE_PRIMARY    : UserAuthRoleType.UART_VOTING_CAPTURE_PRIMARY,
        VOTING_APPROVAL_SECONDARY : UserAuthRoleType.UART_VOTING_APPROVAL_SECONDARY,
        VOTING_APPROVAL_FINAL     : UserAuthRoleType.UART_VOTING_APPROVAL_FINAL
    };

    //====================================================================
    // static api

    /// check if user and server info is loaded, load if not
    static checkUser( complete?: () => void ): void
    {
        //- check don't load more than once -----
        if ( Ut.svrVer == "" ) Ut.loadUser( complete );
        else if ( complete ) complete();
    }

    /// force loading user and server info
    static loadUser( complete?: () => void ): void
    {
        //- load server and user -----
        Ut.user_id = 0;
        FtRapi.callRead( 'serverExist', 0, {
            success: ( { message }, data ) => Ut.svrVer = data[0],
            complete: () => {
                FtRapi.callRead( 'authLoginCheck', 0, {
                    success: ( { message }, data ) => {
                        Ut.user_id      = data[0].id;
                        Ut.display_name = data[0].disp_name;
                        Ut.role_id      = data[2];
                        Ut.user_roles   = data[3];
                    },
                    error: () => { // clear if not logged in
                        Ut.user_id      = 0;
                        Ut.display_name = "";
                        Ut.role_id      = 0;
                        Ut.user_roles   = [];
                    },
                    complete: () => {
                        if ( complete ) complete();
                    },
                });
            }
        });
    }

    /// test if current user has specified role
    static roleTest( role: number, role2?: number, role3?: number, role4?: number, role5?: number, role6?: number ): boolean
    {
        if ( Ut.user_roles.includes( role ) ) return true;
        if ( role2 != undefined && Ut.user_roles.includes( role2 ) ) return true;
        if ( role3 != undefined && Ut.user_roles.includes( role3 ) ) return true;
        if ( role4 != undefined && Ut.user_roles.includes( role4 ) ) return true;
        if ( role5 != undefined && Ut.user_roles.includes( role5 ) ) return true;
        if ( role6 != undefined && Ut.user_roles.includes( role6 ) ) return true;
        return false;
    }

    static roleTxtClr(): string
    {
        switch ( Ut.role_id ) {
            case this.RoleT.UART_SYSTEMADMIN:        return '#ff6060';
            case this.RoleT.UART_NOB:                return '#ff6060';
            case this.RoleT.UART_ADMINISTRATION:     return '#00b0f0';
            case this.RoleT.UART_ADMINJUSTICE:       return '#fc4efb';
            case this.RoleT.UART_NLF:                return '#ffc000';
            case this.RoleT.UART_REGIONAL_SECRETARY: return '#09fa80';
            case this.RoleT.UART_REGIONAL_TREASURER: return '#ffff00';
            case this.RoleT.UART_ADMIN_CLERK:        return '#00b0f0';
        }
        return '#ff0000';
    }

    static feBaseUrl(): string
    {
        return FtAppInfo.isDebug ? this.feBaseUrlDebug : this.feBaseUrlRelease;
    }

    /// load basic lookups if not already loaded
    ///
    /// Example:
    ///     setBusy( true );
    ///     Ut.loadLookups( {
    ///         success: ( result, data ) => setBusy( false ),
    ///         error: ( { message } ) => ftPopupMessage( setPopup, "Error", message, { onClose: nav.pop } ),
    ///     });
    ///
    static loadLookups( args: FtRapiCallArgs ): void
    {
        //- check already loaded -----
        if ( this.lkpArcRepoMediaType != null ) {
            if ( args.success != null ) args.success( new FtRapiResult(), [] as any[] );
            if ( args.complete != null ) args.complete( new FtRapiResult() );
            return;
        }

        //- load from server -----
        FtRapi.callList( 'basicLookups', {
            success: ( result, data ) => {
                this.lkpAttendeeTitle     = data[0];
                this.lkpProvince          = data[1];
                this.lkpContactMethod     = data[2];
                this.lkpPayMethod         = data[3];
                this.lkpAttendeeType      = data[4];
                this.lkpRegisterStatus    = data[5];
                this.lkpArcRepoMediaType  = data[6];
                this.lkpAssemblyType      = data[7];
                this.lkpMaritalStatus     = data[8];
                this.lkpGender            = data[9];
                this.lkpTaskCategory      = data[10];
                this.lkpJusticeStatus     = data[11];
                this.lkpJusticeCategory   = data[12];
                this.lkpPastorStatus      = data[13];
                this.lkpPastorTitle       = data[14];
                this.lkpPastorPosition    = data[15];
                this.lkpUserAuthRoleType  = data[16];
                this.lkpRetireType        = data[17];
                if ( args.success != null ) args.success( result, data );
            },
            error: ( result ) => {
                if ( args.error != null ) args.error( result );
            },
            complete: ( result ) => {
                if ( args.complete != null ) args.complete( result );
            }
        });
    }

    /// open url in new tab, url is relative to server
    ///
    /// Example: Ut.openUrlSvr( 'myreport' );
    static openUrlSvr( url: string, args: any = {} ): void
    {
        Ft.openUrl( FtAppInfo.baseUrl + url, args );
    }

    /// open url in new tab, url is relative to server
    ///
    /// Example: Ut.openReport( 'myreport', prms );
    static openReport( url: string, parms: any = {} ): void
    {
        Ft.openUrl( FtAppInfo.baseUrl + url, { p: Ft.objToJsonBase64( parms ) } );
    }
}
