import PastorTitleBase from './base/pastorTitleBase';

//********************************************************************
// PastorTitle class
// @FTGEN_PROGRESS: busy
export default class PastorTitle extends PastorTitleBase
{
    //====================================================================
    // static data members

    // #FTGEN_CONSTS_A#
    // #FTGEN_CONSTS_Z#

    //====================================================================
    // init
    
    constructor()
    {
        super();
    }
}
