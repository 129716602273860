import LanguageBase from './base/languageBase';

//********************************************************************
// Language class
// @FTGEN_PROGRESS: busy
export default class Language extends LanguageBase
{
    //====================================================================
    // static data members

    // #FTGEN_CONSTS_A#
    // #FTGEN_CONSTS_Z#

    //====================================================================
    // init
    
    constructor()
    {
        super();
    }
}
