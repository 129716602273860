import React, { useState, useEffect } from 'react';
import type { FtPageProps } from '../first/ftTypes';
import Ft from '../first/ft';
import FtRapi from '../first/ftRapi';
import FtFrame from '../first/ftFrame';
import FtCanvas from '../first/ftCanvas';
import FtSpacer from '../first/ftSpacer';
import FtForm from '../first/ftForm';
import FtText from '../first/ftText';
import FtFmSubmit from '../first/ftFmSubmit';
import FtFmTextField from '../first/ftFmTextField';
import FtFmTextareaField from '../first/ftFmTextareaField';
import { ftBusy } from '../first/ftBusy';
import { ftPopupMessage } from '../first/ftPopupMessage';
import Th from '../general/th';

import Pastor               from '../models/pastor';
import PastorQualification  from '../models/pastorQualification';

//********************************************************************
// PageQualificationEdit
// @FTGEN_PROGRESS: done
// ...todo: specify props here...
export default function PageQualificationEdit( { navigation, options }: FtPageProps ): JSX.Element
{
    //====================================================================
    // hooks: no conditions here

    //- hooks: state -----
    const nav = Ft.useNav( navigation );
    const { pastorId, qualId } = nav.params ?? { pastorId: 0, qualId: 0 };    // unpack parameters passed from calling page
    const [ popup     , setPopup     ] = useState( <></> ); // placeholder for any popup message/options
    const [ busy      , setBusy      ] = useState( false );
    const [ pnPastor  , setPnPastor  ] = useState<Pastor|null>( null );
    const [ mdlQual   , setMdlQual   ] = useState<PastorQualification|null>( null );

    //- hooks: effect/startup -----
    useEffect( onLoad, [] );

    //====================================================================
    // render

    if ( busy || Ft.eAny( mdlQual, pnPastor ) ) return ftBusy( { popup: popup } );

    return (<FtCanvas {...Th.page}>
        {popup}

        <FtFrame text="Pastor" {...Th.frame} ss='12|12|12|10|6'>
            <FtText ss="12" text={`Name : ${pnPastor?.initials} ${pnPastor?.first_name} ${pnPastor?.surname}`} {...Th.textLg} />
            <FtText ss="12" text={`Member No : ${pnPastor?.member_no}`} {...Th.textLg} />
        </FtFrame>

        <FtFrame text={(qualId == 0 ? 'New' : 'Edit') + ' Pastor Qualification'} {...Th.frame}>
            <FtForm {...Th.form}
                models={{ m: mdlQual }}
                onSubmit={onSave}
            >
                <FtFmTextField     name='m.awarding_date'   label='Awarding Date'   {...Th.field} />
                <FtFmTextField     name='m.category'        label='Category'        {...Th.field} />
                <FtFmTextField     name='m.accredited_by'   label='Accredited By'   {...Th.field} />
                <FtFmTextField     name='m.obtained_from'   label='Obtained From'   {...Th.field} />
                <FtFmTextareaField name='m.description'     label='Description'     {...Th.fieldLg} />
                <FtSpacer />
                <FtFmSubmit ss='3|2:2' label='Save' {...Th.submit} />
                <FtSpacer />
            </FtForm>
        </FtFrame>

    </FtCanvas>);

    //====================================================================
    // event handlers

    function onLoad(): void
    {
        setBusy( true );
        if ( qualId != 0 ) {  // existing record
            FtRapi.callRead( 'pastorQualificationRead', qualId, {
                success: ( result, data ) => {
                    setMdlQual( data[0] );
                    setPnPastor( data[1] );
                },
                error: ( { message } ) => ftPopupMessage( setPopup, "Error", message, { onClose: nav.pop } ),
                complete: () => setBusy( false ),
            });
        } else {             // new record
            FtRapi.callRead( 'pastorRead', pastorId, {
                success: ( result, data ) => {
                    setPnPastor( data[0] );
                    const rec = new PastorQualification();
                    rec.pastor_id = pastorId;
                    setMdlQual( rec );
                },
                error: ( { message } ) => ftPopupMessage( setPopup, "Error", message, { btn: "Close", onClose: () => nav.pop() } ),
                complete: () => setBusy( false ),
            });
        }
    }

    function onSave(): void
    {
        setBusy( true );
        if ( qualId != 0 ) {  // existing record
            FtRapi.callUpdate( 'pastorQualificationUpdate', qualId, {
                inRec: mdlQual,
                success: ( { message }, data ) => ftPopupMessage( setPopup, "Success", message, { btn: "Close", onClose: nav.pop } ),
                error: ( { message } ) => ftPopupMessage( setPopup, "Error", message ),
                complete: () => setBusy( false ),
            });
        } else {             // new record
            FtRapi.callNew( 'pastorQualificationNew', {
                inRec: mdlQual,
                success: ( { message }, data ) => {
                    ftPopupMessage( setPopup, "Success", message );
                    const rec = new PastorQualification();
                    rec.pastor_id = pastorId;
                    setMdlQual( rec );
                },
                error: ( { message } ) => ftPopupMessage( setPopup, "Error", message ),
                complete: () => setBusy( false ),
            });
        }
    }
}