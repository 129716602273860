import React, { useState, useEffect } from 'react';
import type { FtPageProps, FtDgAction } from '../first/ftTypes';
import Ft from '../first/ft';
import FtRapi from '../first/ftRapi';
import FtFrame from '../first/ftFrame';
import FtCanvas from '../first/ftCanvas';
import FtCon from '../first/ftCon';
import FtText from '../first/ftText';
import FtButton from '../first/ftButton';
import FtDataGrid from '../first/ftDataGrid';
import { ftBusy } from '../first/ftBusy';
import { ftPopupMessage } from '../first/ftPopupMessage';
import { ftPopupConfirm } from '../first/ftPopupOptions';
import Th from '../general/th';

import Assembly                           from '../models/assembly';
import AssemblyProperty                   from '../models/assemblyProperty';

//********************************************************************
// PagePropertySearch
// @FTGEN_PROGRESS: done
// ...todo: specify props here...
export default function PagePropertySearch( { navigation, options }: FtPageProps ): JSX.Element
{
    //====================================================================
    // hooks: no conditions here
    
    //- hooks: state -----
    const nav = Ft.useNav( navigation );
    const { assemblyId } = nav.params ?? { assemblyId: 0 };
    const [ popup        , setPopup        ] = useState( <></> ); // placeholder for any popup message/options
    const [ busy         , setBusy         ] = useState( false );
    const [ pnAssembly   , setPnAssembly   ] = useState<Assembly|null>( null );
    const [ lstProperty  , setLstProperty  ] = useState<AssemblyProperty[]>( [] );

    //- hooks: effect/startup -----
    useEffect( onLoad, [] );

    //====================================================================
    // render
    
    if ( busy || Ft.eAny( pnAssembly )) return ftBusy( { popup: popup } );

    return (<FtCanvas {...Th.page}>
        {popup}

        <FtFrame text="Assembly" {...Th.frame} ss='12|12|12|10|6'>
            <FtText ss="12" text={`Name : ${pnAssembly?.assembly_name}`} {...Th.textLg} />
            <FtText ss="12" text={`Number : ${pnAssembly?.assembly_no}`} {...Th.textLg} />
        </FtFrame>

        <FtCon ss='12|12|12|10|6' md='rw' lt='z,a' marB={15}>
            <FtButton label="Refresh"      onTap={onSearch}           {...Th.button} padR={20}/>
            <FtButton label="New Property" onTap={onClickNewProperty} {...Th.button} />
        </FtCon>
                
        <FtFrame text='Search Results' {...Th.frame} ss='12'>
            <FtDataGrid {...Th.grid} pgsize={50}
                data={lstProperty}
                cols={[
                    { fld: ''                  , hd: 'Edit'     , type: 'act'  , flex: 1,  fmt: 'mc-pencil', clr: '#808080', act: onTapAssemblyPropertyEdit },
                    { fld: 'physical_adr_1'       , hd: 'Address'  , type: 'txt'  , flex: 3 },
                    { fld: 'physical_adr_area'    , hd: 'Area'     , type: 'txt'  , flex: 3 },
                    { fld: 'physical_adr_city'    , hd: 'City'     , type: 'txt'  , flex: 3 },
                    { fld: 'physical_adr_region'  , hd: 'Region'   , type: 'txt'  , flex: 3 },
                    { fld: 'erf_no'               , hd: 'Erf No'   , type: 'txt'  , flex: 3 },
                    { fld: ''                     , hd: 'Delete'   , type: 'act'  , flex: 1,  fmt: 'fa-trash', clr: '#808080', act: onTapAssemblyPropertyDelete },
                ]}
            />
        </FtFrame>
        

    </FtCanvas>);

    //====================================================================
    // event handlers
    
    function onLoad(): void 
    {
        setBusy( true );
        FtRapi.callRead( 'assemblyRead', assemblyId, {
            success: ( result, data ) => {
                setPnAssembly( data[0] );
                onSearch();
            },
            error: ( { message } ) => ftPopupMessage( setPopup, "Error", message, { btn: "Close", onClose: () => nav.pop() } ),  
            complete: () => setBusy( false ),
        });
    }

    function onSearch(): void
    {
        setLstProperty( [] );
        setBusy( true );
        FtRapi.callList( 'assemblyPropertySearch', {
            urlParms: { prm_assembly_id : assemblyId },
            success: ( result, data ) => setLstProperty( data[0] ),
            error: ( { message } ) => ftPopupMessage( setPopup, "Error", message ),
            complete: () => setBusy( false ),
        });
    }

    function onTapAssemblyPropertyEdit( { dataId }: FtDgAction ): void
    {
        nav.push( 'PropertyEdit', { assemblyId: assemblyId, propertyId: dataId } );
    }
    
    function onTapAssemblyPropertyDelete( { dataId }: FtDgAction ): void
    {
        ftPopupConfirm( setPopup, 'Confirmation', "Are you sure you want to delete this record?", {
            btnA: 'Yes', onA: () => {
                setLstProperty( [] );
                setBusy( true );
                FtRapi.callDelete( 'AssemblyPropertyDelete', dataId, {
                    success: ( { message }, data ) => ftPopupMessage( setPopup, "Success", message, { onClose: onSearch } ),
                    error: ( { message } ) => ftPopupMessage( setPopup, "Error", message ),
                    complete: () => setBusy( false ),
                });
            },
            btnB: 'No', onB: () => {}
        });
    }
    
    function onClickNewProperty(): void
    {
        nav.push( 'PropertyEdit', { assemblyId: assemblyId, propertyId: 0 } );
    }
}
