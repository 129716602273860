import ArcUploadBase from './base/arcUploadBase';

//********************************************************************
// ArcUpload class
// @FTGEN_PROGRESS: busy
export default class ArcUpload extends ArcUploadBase
{
    //====================================================================
    // static data members

    // #FTGEN_CONSTS_A#
    // #FTGEN_CONSTS_Z#

    //====================================================================
    // init
    
    constructor()
    {
        super();
    }
}
