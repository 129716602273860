import React, { useState, useEffect } from 'react';
import type { FtPageProps } from '../first/ftTypes';
import Ft from '../first/ft';
import FtFrame from '../first/ftFrame';
import FtCanvas from '../first/ftCanvas';
import FtSpacer from '../first/ftSpacer';
import FtForm from '../first/ftForm';
import FtFmSubmit from '../first/ftFmSubmit';
import { ftBusy } from '../first/ftBusy';
import { ftPopupMessage } from '../first/ftPopupMessage';
import Th from '../general/th';
import Ut from '../general/ut';

import Attendee from '../models/attendee';

//********************************************************************
// PageReportConfregisQuorumDetail
// @FTGEN_PROGRESS: done
// ...todo: specify props here...
export default function PageReportConfregisQuorumDetail( { navigation, options }: FtPageProps ): JSX.Element
{
    //====================================================================
    // hooks: no conditions here
    
    //- hooks: state -----
    const nav = Ft.useNav( navigation );
    const [ popup                  , setPopup                  ] = useState( <></> ); // placeholder for any popup message/options
    const [ busy                   , setBusy                   ] = useState( false );
    const [ mdlAttendee            , setMdlAttendee            ] = useState( new Attendee() );

    //- hooks: effect/startup -----
    // useEffect( onLoad, [] );

    //====================================================================
    // render
    
    if ( busy ) return ftBusy( { popup: popup } ); // || Ft.eAny( mdlDataTest, mdlCatClass )

    return (<FtCanvas {...Th.page}>
        {popup}

        <FtFrame text='Report Parameters' {...Th.frame} >
            <FtForm {...Th.form} lt='C,A'
                models={{ m: mdlAttendee }}
                onSubmit={onGenerate}
            >
                {/* <FtFmDropdownField name='m.payment_method_id'   label='Payment method'  data={Ut.lkpPayMethod!} /> */}
                <FtSpacer />
                <FtFmSubmit label='Generate Report' {...Th.button} />
                <FtSpacer />
            </FtForm>
        </FtFrame>                

    </FtCanvas>);

    //====================================================================
    // event handlers

    function onLoad(): void 
    {
        setBusy( true );
        Ut.loadLookups( {
            success: ( result, data ) => setBusy( false ),
            error: ( { message } ) => ftPopupMessage( setPopup, "Error", message, { onClose: nav.pop } ),
        });        
    }

    function onGenerate(): void
    {
        Ut.openReport( 'reportConfregisQuorumDetail' );
    }

}
