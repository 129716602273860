import React, { useState, useEffect } from 'react';
import type { FtPageProps, FtDgAction } from '../first/ftTypes';
import Ft from '../first/ft';
import FtFrame from '../first/ftFrame';
import FtCanvas from '../first/ftCanvas';
import FtSpacer from '../first/ftSpacer';
import FtForm from '../first/ftForm';
import FtFmSubmit from '../first/ftFmSubmit';
import FtFmDateField from '../first/ftFmDateField';
import Th from '../general/th';
import Ut from '../general/ut';

//********************************************************************
// PageReportPastorMovementCalled
// @FTGEN_PROGRESS: done
// ...todo: specify props here...
export default function PageReportPastorMovementCalled( { navigation, options }: FtPageProps ): JSX.Element
{
    //====================================================================
    // hooks: no conditions here
    
    //- hooks: state -----
    const nav = Ft.useNav( navigation );
    const [ popup      , setPopup     ] = useState( <></> ); // placeholder for any popup message/options
    const [ mdlParms   , setMdlParms  ] = useState( {
        date_fm: Ft.dtmAsDate( '2001-01-01' ),
        date_to: Ft.dtmAsDate(),
    } as any );

    //- hooks: effect/startup -----
    // useEffect( onLoad, [] );

    //====================================================================
    // render
    
    return (<FtCanvas {...Th.page}>
        {popup}

        <FtFrame text='Report' {...Th.frame}>
            <FtForm {...Th.form}
                models={{ m: mdlParms }}
                onSubmit={onSubmitReport}
            >
                <FtFmDateField     name="m.date_fm"   label="Date From"   {...Th.field} />
                <FtFmDateField     name="m.date_to"   label="Date To"     {...Th.field} />
                <FtSpacer />
                <FtFmSubmit label='Generate Report' {...Th.reportBtn2} />
                <FtSpacer />
            </FtForm>
        </FtFrame>

    </FtCanvas>);

    //====================================================================
    // event handlers

    function onSubmitReport(): void
    {
        Ut.openReport( 'reportPastorMovementCalled', {
            date_fm: Ft.dtFmt( mdlParms.date_fm ),
            date_to: Ft.dtFmt( mdlParms.date_to ),
        } );
    }    
}
