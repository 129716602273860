import FtRapi from './first/ftRapi';
import AdminTask from './models/adminTask';
import AdminTaskCategory from './models/adminTaskCategory';
import ArcRepo from './models/arcRepo';
import ArcRepoMediaType from './models/arcRepoMediaType';
import ArcUpload from './models/arcUpload';
import Assembly from './models/assembly';
import AssemblyProperty from './models/assemblyProperty';
import AssemblyRegion from './models/assemblyRegion';
import AssemblyType from './models/assemblyType';
import Attendee from './models/attendee';
import AttendeeTitle from './models/attendeeTitle';
import AttendeeType from './models/attendeeType';
import AuditAction from './models/auditAction';
import CandidateStatus from './models/candidateStatus';
import Conference from './models/conference';
import ConfRegisterStatus from './models/confRegisterStatus';
import ContactMethod from './models/contactMethod';
import Gender from './models/gender';
import GenLookup from './models/genLookup';
import Justice from './models/justice';
import JusticeCategory from './models/justiceCategory';
import JusticeFile from './models/justiceFile';
import JusticeNote from './models/justiceNote';
import JusticeStatus from './models/justiceStatus';
import Language from './models/language';
import MaritalStatus from './models/maritalStatus';
import Parameter from './models/parameter';
import Pastor from './models/pastor';
import PastorFile from './models/pastorFile';
import PastorHistory from './models/pastorHistory';
import PastorPosition from './models/pastorPosition';
import PastorQualification from './models/pastorQualification';
import PastorRetireType from './models/pastorRetireType';
import PastorStatus from './models/pastorStatus';
import PastorTitle from './models/pastorTitle';
import PaymentMethod from './models/paymentMethod';
import PositionStatus from './models/positionStatus';
import Reception from './models/reception';
import RoundStatus from './models/roundStatus';
import RsaProvince from './models/rsaProvince';
import UserAuthRole from './models/userAuthRole';
import UserAuthRoleType from './models/userAuthRoleType';
import UserDevice from './models/userDevice';
import UserType from './models/userType';
import Userz from './models/userz';
import VotingCandidate from './models/votingCandidate';
import VotingPosition from './models/votingPosition';
import VotingRound from './models/votingRound';

//********************************************************************
// Models registry class
export default class Models 
{
    //====================================================================
    // static api

    /// Adds registered model classes to FtRapi.classMap
    /// Use in App():
    ///   Models.addModelsClassMap();
    static addModelsClassMap(): void
    {
        FtRapi.classMap['AdminTask'] = AdminTask;
        FtRapi.classMap['AdminTaskCategory'] = AdminTaskCategory;
        FtRapi.classMap['ArcRepo'] = ArcRepo;
        FtRapi.classMap['ArcRepoMediaType'] = ArcRepoMediaType;
        FtRapi.classMap['ArcUpload'] = ArcUpload;
        FtRapi.classMap['Assembly'] = Assembly;
        FtRapi.classMap['AssemblyProperty'] = AssemblyProperty;
        FtRapi.classMap['AssemblyRegion'] = AssemblyRegion;
        FtRapi.classMap['AssemblyType'] = AssemblyType;
        FtRapi.classMap['Attendee'] = Attendee;
        FtRapi.classMap['AttendeeTitle'] = AttendeeTitle;
        FtRapi.classMap['AttendeeType'] = AttendeeType;
        FtRapi.classMap['AuditAction'] = AuditAction;
        FtRapi.classMap['CandidateStatus'] = CandidateStatus;
        FtRapi.classMap['Conference'] = Conference;
        FtRapi.classMap['ConfRegisterStatus'] = ConfRegisterStatus;
        FtRapi.classMap['ContactMethod'] = ContactMethod;
        FtRapi.classMap['Gender'] = Gender;
        FtRapi.classMap['GenLookup'] = GenLookup;
        FtRapi.classMap['Justice'] = Justice;
        FtRapi.classMap['JusticeCategory'] = JusticeCategory;
        FtRapi.classMap['JusticeFile'] = JusticeFile;
        FtRapi.classMap['JusticeNote'] = JusticeNote;
        FtRapi.classMap['JusticeStatus'] = JusticeStatus;
        FtRapi.classMap['Language'] = Language;
        FtRapi.classMap['MaritalStatus'] = MaritalStatus;
        FtRapi.classMap['Parameter'] = Parameter;
        FtRapi.classMap['Pastor'] = Pastor;
        FtRapi.classMap['PastorFile'] = PastorFile;
        FtRapi.classMap['PastorHistory'] = PastorHistory;
        FtRapi.classMap['PastorPosition'] = PastorPosition;
        FtRapi.classMap['PastorQualification'] = PastorQualification;
        FtRapi.classMap['PastorRetireType'] = PastorRetireType;
        FtRapi.classMap['PastorStatus'] = PastorStatus;
        FtRapi.classMap['PastorTitle'] = PastorTitle;
        FtRapi.classMap['PaymentMethod'] = PaymentMethod;
        FtRapi.classMap['PositionStatus'] = PositionStatus;
        FtRapi.classMap['Reception'] = Reception;
        FtRapi.classMap['RoundStatus'] = RoundStatus;
        FtRapi.classMap['RsaProvince'] = RsaProvince;
        FtRapi.classMap['UserAuthRole'] = UserAuthRole;
        FtRapi.classMap['UserAuthRoleType'] = UserAuthRoleType;
        FtRapi.classMap['UserDevice'] = UserDevice;
        FtRapi.classMap['UserType'] = UserType;
        FtRapi.classMap['Userz'] = Userz;
        FtRapi.classMap['VotingCandidate'] = VotingCandidate;
        FtRapi.classMap['VotingPosition'] = VotingPosition;
        FtRapi.classMap['VotingRound'] = VotingRound;
    }
}
