import AssemblyTypeBase from './base/assemblyTypeBase';

//********************************************************************
// AssemblyType class
// @FTGEN_PROGRESS: busy
export default class AssemblyType extends AssemblyTypeBase
{
    //====================================================================
    // static data members

    // #FTGEN_CONSTS_A#
    static AT_AFFILIATED = 1;
    static AT_NORMAL = 2;
    static AT_DEPARTMENT = 3;
    static AT_MISSION = 4;
    static AT_SPECIALIZED = 5;
    static AT_PROJECT = 6;
    // #FTGEN_CONSTS_Z#

    //====================================================================
    // init
    
    constructor()
    {
        super();
    }
}
