import PastorBase from './base/pastorBase';

//********************************************************************
// Pastor class
// @FTGEN_PROGRESS: busy
export default class Pastor extends PastorBase
{
    //====================================================================
    // static data members

    // #FTGEN_CONSTS_A#
    // #FTGEN_CONSTS_Z#

    //====================================================================
    // init
    
    constructor()
    {
        super();
    }
}
