import React, { useState, useEffect } from 'react';
import type { FtPageProps, FtDgAction } from '../first/ftTypes';
import Ft from '../first/ft';
import FtFrame from '../first/ftFrame';
import FtCanvas from '../first/ftCanvas';
import FtSpacer from '../first/ftSpacer';
import FtForm from '../first/ftForm';
import FtFmSubmit from '../first/ftFmSubmit';
import FtFmTextField from '../first/ftFmTextField';
import Th from '../general/th';
import Ut from '../general/ut';

//********************************************************************
// PageReportAssemblyPastorsList
// @FTGEN_PROGRESS: done
// ...todo: specify props here...
export default function PageReportAssemblyPastorsList( { navigation, options }: FtPageProps ): JSX.Element
{
    //====================================================================
    // hooks: no conditions here
    
    //- hooks: state -----
    const nav = Ft.useNav( navigation );
    const [ popup      , setPopup     ] = useState( <></> ); // placeholder for any popup message/options
    const [ mdlParms   , setMdlParms  ] = useState( {
        region_no_fm: '',
        region_no_to: '',
    } as any );

    //- hooks: effect/startup -----
    // useEffect( onLoad, [] );

    //====================================================================
    // render
    
    return (<FtCanvas {...Th.page}>
        {popup}

        <FtFrame text='Report' {...Th.frame}>
            <FtForm {...Th.form}
                models={{ m: mdlParms }}
                onSubmit={onSubmitReport}
            >
                <FtFmTextField     name="m.region_no_fm"    label="Region No From"   {...Th.field} />
                <FtFmTextField     name="m.region_no_to"    label="Region No To"     {...Th.field} />            
                <FtSpacer />
                <FtFmSubmit label='Generate Report' {...Th.reportBtn2} />
                <FtSpacer />
            </FtForm>
        </FtFrame>

    </FtCanvas>);

    //====================================================================
    // event handlers
    
    function onSubmitReport(): void
    {
        Ut.openReport( 'reportAssemblyPastorsList', mdlParms );
    }
}
