import React, { useState, useEffect } from 'react';
import type { FtPageProps } from '../first/ftTypes';
import Ft from '../first/ft';
import FtRapi from '../first/ftRapi';
import FtFrame from '../first/ftFrame';
import FtCanvas from '../first/ftCanvas';
import FtSpacer from '../first/ftSpacer';
import FtForm from '../first/ftForm';
import FtFmSubmit from '../first/ftFmSubmit';
import FtFmTextField from '../first/ftFmTextField';
import FtFmTextareaField from '../first/ftFmTextareaField';
import FtFmIntegerField from '../first/ftFmIntegerField';
import FtFmDateField from '../first/ftFmDateField';
import FtFmCheckboxField from '../first/ftFmCheckboxField';
import FtFmDropdownField from '../first/ftFmDropdownField';
import FtFmSearchField from '../first/ftFmSearchField';
import { ftBusy } from '../first/ftBusy';
import { ftPopupMessage } from '../first/ftPopupMessage';
import Th from '../general/th';
import Ut from '../general/ut';

import GenLookup       from '../models/genLookup';
import Assembly        from '../models/assembly';

//********************************************************************
// PageAssemblyEdit
// @FTGEN_PROGRESS: done
// ...todo: specify props here...
export default function PageAssemblyEdit( { navigation, options }: FtPageProps ): JSX.Element
{
    //====================================================================
    // hooks: no conditions here
    
    //- hooks: state -----
    const nav = Ft.useNav( navigation );
    const { assemblyId } = nav.params ?? { assemblyId: 0 };    // unpack parameters passed from calling page
    const [ popup        , setPopup        ] = useState( <></> ); // placeholder for any popup message/options
    const [ busy         , setBusy         ] = useState( false );
    const [ mdlAssembly  , setMdlAssembly  ] = useState<Assembly|null>( null );
    const [ lkpRegion    , setLkpRegion    ] = useState<GenLookup[]>( [] );

    //- hooks: effect/startup -----
    useEffect( onLoad, [] );

    //====================================================================
    // render
    
    if ( busy || Ft.eAny( mdlAssembly, Ut.lkpAssemblyType ) ) return ftBusy( { popup: popup } );

    return (<FtCanvas {...Th.page}>
        {popup}

        <FtFrame text={(assemblyId == 0 ? 'New' : 'Edit') + ' Assembly'} {...Th.frame}>
            <FtForm {...Th.form}
                models={{ m: mdlAssembly }}
                onSubmit={onSave}
            >
                <FtFmTextField     name='m.assembly_no'                        label='Assembly No'                        {...Th.field} ftreq />
                <FtFmTextField     name='m.assembly_name'                      label='Assembly Name'                      {...Th.field} ftreq />

                <FtFmDropdownField name='m.type_id'                            label='Type'                               {...Th.field} data={Ut.lkpAssemblyType} />
                <FtFmSearchField   name='m.region_id'                          label='Region'                             {...Th.field} data={lkpRegion} />

                <FtFmTextField     name='m.email'                              label='Email'                              {...Th.field} />
                <FtFmTextField     name='m.website'                            label='Website'                            {...Th.field} />

                <FtFmTextField     name='m.phone_no'                           label='Phone No'                           {...Th.field} />
                <FtFmTextField     name='m.phone_extension'                    label='Phone Extension'                    {...Th.field} />

                <FtFmTextField     name='m.procedure'                          label='Procedure'                          {...Th.field} />
                <FtFmTextField     name='m.fax_no'                             label='Fax No'                             {...Th.field} />
                <FtSpacer />

                <FtFmTextField     name='m.physical_adr_1'                     label='Physical Address'                   {...Th.field} />
                <FtFmTextField     name='m.postal_adr_1'                       label='Postal Address'                     {...Th.field} />

                <FtFmTextField     name='m.physical_adr_2'                     label=''                                   {...Th.field} />
                <FtFmTextField     name='m.postal_adr_2'                       label=''                                   {...Th.field} />

                <FtFmTextField     name='m.physical_adr_city'                  label='City'                               {...Th.field} />
                <FtFmTextField     name='m.postal_adr_city'                    label='City'                               {...Th.field} />

                <FtFmTextField     name='m.physical_adr_code'                  label='Code'                               {...Th.field} />
                <FtFmTextField     name='m.postal_code'                        label='Code'                               {...Th.field} />
                <FtSpacer />

                <FtFmCheckboxField name='m.asset_register'                     label='Asset Register'                     {...Th.field} />
                <FtSpacer />
                <FtFmTextField     name='m.bank'                               label='Bank'                               {...Th.field} />
                <FtFmTextField     name='m.bank_branch'                        label='Bank Branch'                        {...Th.field} />
                <FtFmTextField     name='m.bank_account'                       label='Bank Account'                       {...Th.field} />
                <FtFmTextField     name='m.bank_account_type'                  label='Bank Account Type'                  {...Th.field} />
                <FtSpacer />

                <FtFmTextField     name='m.treasurer_name'                     label='Treasurer Name'                     {...Th.field} />
                <FtFmTextField     name='m.secretary_name'                     label='Secretary Name'                     {...Th.field} />

                <FtFmTextField     name='m.treasurer_phone_extension'          label='Phone Extension'                    {...Th.field} />
                <FtFmTextField     name='m.secretary_phone_extension'          label='Phone Extension'                    {...Th.field} />

                <FtFmTextField     name='m.treasurer_procedure'                label='Phone Procedure'                    {...Th.field} />
                <FtFmTextField     name='m.secretary_procedure'                label='Phone Procedure'                    {...Th.field} />

                <FtFmTextField     name='m.treasurer_phone'                    label='Phone Number'                       {...Th.field} />
                <FtFmTextField     name='m.secretary_phone'                    label='Phone Number'                       {...Th.field} />

                <FtFmTextField     name='m.treasurer_postal_adr_1'             label='Postal Address'                     {...Th.field} />
                <FtFmTextField     name='m.secretary_postal_adr_1'             label='Postal Address'                     {...Th.field} />

                <FtFmTextField     name='m.treasurer_postal_adr_2'             label=''                                   {...Th.field} />
                <FtFmTextField     name='m.secretary_postal_adr_2'             label=''                                   {...Th.field} />

                <FtFmTextField     name='m.treasurer_postal_adr_city'          label='City'                               {...Th.field} />
                <FtFmTextField     name='m.secretary_postal_adr_city'          label='City'                               {...Th.field} />

                <FtFmTextField     name='m.treasurer_postal_code'              label='Code'                               {...Th.field} />
                <FtFmTextField     name='m.secretary_postal_adr_code'          label='Code'                               {...Th.field} />
                <FtSpacer />

                <FtFmTextField     name='m.vicechairperson_name'               label='Vice-Chairperson Name'              {...Th.field} />
                <FtFmDateField     name='m.input_date'                         label='Input Date'                         {...Th.field} />

                <FtFmTextField     name='m.vicechairperson_phone_extension'    label='Phone Extension'                    {...Th.field} />
                <FtFmDateField     name='m.file_date'                          label='File Date'                          {...Th.field} />

                <FtFmTextField     name='m.vicechairperson_procedure'          label='Phone Procedure'                    {...Th.field} />
                <FtFmCheckboxField name='m.is_filed'                           label='Is Filed'                           {...Th.field} />

                <FtFmTextField     name='m.vicechairperson_phone'              label='Phone Number'                       {...Th.field} />
                <FtFmTextField     name='m.move_no'                            label='Move No'                            {...Th.field} />

                <FtFmTextField     name='m.vicechairperson_postal_adr_1'       label='Postal Address'                     {...Th.field} />
                <FtFmDateField     name='m.date_registered'                    label='Date Registered'                    {...Th.field} />

                <FtFmTextField     name='m.vicechairperson_postal_adr_2'       label=''                                   {...Th.field} />
                <FtFmDateField     name='m.census_date'                        label='Census Date'                        {...Th.field} />

                <FtFmTextField     name='m.vicechairperson_postal_adr_city'    label='City'                               {...Th.field} />
                <FtFmTextField     name='m.census_women_count'                 label='Census Women Count'                 {...Th.field} />

                <FtFmTextField     name='m.vicechairperson_postal_adr_code'    label='Code'                               {...Th.field} />
                <FtFmTextField     name='m.census_men_count'                   label='Census Men Count'                   {...Th.field} />
                <FtSpacer />

                <FtFmTextField     name='m.census_youth_count'                 label='Census Youth Count'                 {...Th.field} />
                <FtFmTextField     name='m.census_children_count'              label='Census Children Count'              {...Th.field} />

                <FtFmTextField     name='m.spoken_languages'                   label='Spoken Languages'                   {...Th.field} />
                <FtFmCheckboxField name='m.title_deed'                         label='Title Deed'                         {...Th.field} />

                <FtFmIntegerField  name='m.baptised_water_count'               label='Baptised Water Count'               {...Th.field} />
                <FtFmIntegerField  name='m.baptised_spirit_count'              label='Baptised Spirit Count'              {...Th.field} />

                <FtFmIntegerField  name='m.attending_sunday_service_count'     label='Attending Sunday Service Count'     {...Th.field} />
                <FtFmIntegerField  name='m.attending_sunday_school_count'      label='Attending Sunday School Count'      {...Th.field} />

                <FtFmIntegerField  name='m.attending_over_35_count'            label='Attending Over 35 Count'            {...Th.field} />
                <FtFmIntegerField  name='m.attending_under_35_count'           label='Attending Under 35 Count'           {...Th.field} />

                <FtFmIntegerField  name='m.attending_other_count'              label='Attending Other Count'              {...Th.field} />
                <FtFmCheckboxField name='m.audit_books'                        label='Audit Books'                        {...Th.field} />

                <FtFmCheckboxField name='m.assembly_policy'                    label='Assembly Policy'                    {...Th.field} />
                <FtFmCheckboxField name='m.assembly_gb'                        label='Assembly Gb'                        {...Th.field} />

                <FtFmCheckboxField name='m.site_no_building'                   label='Site No Building'                   {...Th.field} />
                <FtFmCheckboxField name='m.site_and_building'                  label='Site And Building'                  {...Th.field} />

                <FtFmCheckboxField name='m.no_property'                        label='No Property'                        {...Th.field} />
                <FtFmIntegerField  name='m.stat_funds_months'                  label='Stat Funds Months'                  {...Th.field} />

                <FtFmIntegerField  name='m.ordained_pastors_count'             label='Ordained Pastors Count'             {...Th.field} />
                <FtFmIntegerField  name='m.non_ordained_pastors_count'         label='Non Ordained Pastors Count'         {...Th.field} />

                <FtFmCheckboxField name='m.assembly_leader'                    label='Assembly Leader'                    {...Th.field} />
                <FtFmCheckboxField name='m.service_contract'                   label='Service Contract'                   {...Th.field} />

                <FtFmIntegerField  name='m.branch_assemblies_count'            label='Branch Assemblies Count'            {...Th.field} />
                <FtFmCheckboxField name='m.nlf'                                label='Nlf'                                {...Th.field} />

                <FtFmTextField     name='m.gps_lat'                            label='Gps Lat'                            {...Th.field} />
                <FtFmTextField     name='m.web_usedid'                         label='Web Usedid'                         {...Th.field} />

                <FtFmTextField     name='m.gps_long'                           label='Gps Long'                           {...Th.field} />
                <FtFmTextField     name='m.scode'                              label='Scode'                              {...Th.field} />
                
                <FtFmTextField     name='m.bin'                                label='Bin'                                {...Th.field} />
                <FtFmCheckboxField name='m.justice_case_active'                label='Justice Case Active'                {...Th.field} />

                <FtFmTextareaField name='m.notes'                              label='Notes'                              {...Th.field} lines={5} ss='9' labSs='2' />                
                <FtSpacer />

                <FtSpacer />
                <FtFmSubmit ss='3|2:2' label='Save' {...Th.submit} />
                <FtSpacer />
            </FtForm>
        </FtFrame>

    </FtCanvas>);

    //====================================================================
    // event handlers
    
    function onLoad(): void
    {
        //- load lookups -----
        setBusy( true );
        Ut.loadLookups( {
            success: ( result, data ) => setBusy( false ),
            error: ( { message } ) => ftPopupMessage( setPopup, "Error", message, { onClose: nav.pop } ),
        });        
        FtRapi.callList( 'assemblyRegionLookup', {
            success: ( result, data ) => setLkpRegion( data[0] ),
            error: ( { message } ) => ftPopupMessage( setPopup, "Error", message, { onClose: nav.pop } ),
        });

        //- init record -----
        if ( assemblyId != 0 ) {  // existing record
            FtRapi.callRead( 'assemblyRead', assemblyId, {
                success: ( result, data ) => setMdlAssembly( data[0] ),
                error: ( { message } ) => ftPopupMessage( setPopup, "Error", message, { onClose: nav.pop } ),
            });    
        } else {             // new record
            setMdlAssembly( new Assembly() );
        }
    }

    function onSave(): void
    {
        setBusy( true );
        if ( assemblyId != 0 ) {  // existing record
            FtRapi.callUpdate( 'assemblyUpdate', assemblyId, {
                inRec: mdlAssembly,
                success: ( { message }, data ) => ftPopupMessage( setPopup, "Success", message, { btn: "Close", onClose: nav.pop } ),
                error: ( { message } ) => ftPopupMessage( setPopup, "Error", message ),
                complete: () => setBusy( false ),
            });
        } else {             // new record
            FtRapi.callNew( 'assemblyNew', {
                inRec: mdlAssembly,
                success: ( { message }, data ) => {
                    ftPopupMessage( setPopup, "Success", message );
                    setMdlAssembly( new Assembly() );
                },
                error: ( { message } ) => ftPopupMessage( setPopup, "Error", message ),
                complete: () => setBusy( false ),
            });
        }
    }

}
