import React, { useState, useEffect } from 'react';
import type { FtPageProps, FtDgAction } from '../first/ftTypes';
import Ft from '../first/ft';
import FtRapi from '../first/ftRapi';
import FtFrame from '../first/ftFrame';
import FtCanvas from '../first/ftCanvas';
import FtCon from '../first/ftCon';
import FtText from '../first/ftText';
import FtButton from '../first/ftButton';
import FtDataGrid from '../first/ftDataGrid';
import { ftBusy } from '../first/ftBusy';
import { ftPopupMessage } from '../first/ftPopupMessage';
import { ftPopupConfirm } from '../first/ftPopupOptions';
import Th from '../general/th';

import Pastor                             from '../models/pastor';
import PastorQualification                from '../models/pastorQualification';

//********************************************************************
// PageQualificationSearch
// @FTGEN_PROGRESS: done
// ...todo: specify props here...
export default function PageQualificationSearch( { navigation, options }: FtPageProps ): JSX.Element
{
    //====================================================================
    // hooks: no conditions here
    
    //- hooks: state -----
    const nav = Ft.useNav( navigation );
    const { pastorId } = nav.params ?? { pastorId: 0 };
    const [ popup            , setPopup            ] = useState( <></> ); // placeholder for any popup message/options
    const [ busy             , setBusy             ] = useState( false );
    const [ pnPastor         , setPnPastor         ] = useState<Pastor|null>( null );
    const [ lstQualification , setLstQualification ] = useState( [] as PastorQualification[] );

    //- hooks: effect/startup -----
    useEffect( onLoad, [] );

    //====================================================================
    // render
    
    if ( busy || Ft.eAny( pnPastor )) return ftBusy( { popup: popup } );

    return (<FtCanvas {...Th.page}>
        {popup}

        <FtFrame text="Pastor" {...Th.frame} ss='12|12|12|10|6'>
            <FtText ss="12" text={`Name : ${pnPastor?.initials} ${pnPastor?.first_name} ${pnPastor?.surname}`} {...Th.textLg} />
            <FtText ss="12" text={`Member No : ${pnPastor?.member_no}`} {...Th.textLg} />
        </FtFrame>

        <FtCon ss='12|12|12|10|6' md='rw' lt='z,a' marB={15}>
            <FtButton label="Refresh"           onTap={onSearch}                {...Th.button} padR={20}/>
            <FtButton label="New Qualification" onTap={onClickNewQualification} {...Th.button} />
        </FtCon>
                
        <FtFrame text='Search Results' {...Th.frame} ss='12'>
            <FtDataGrid {...Th.grid} pgsize={50}
                data={lstQualification}
                cols={[
                    { fld: ''                , hd: 'Edit'           , type: 'act'  , flex: 1,  fmt: 'mc-pencil', clr: '#808080', act: onTapPastorQualificationEdit },
                    { fld: 'description'     , hd: 'Description'    , type: 'txt'  , flex: 3 },
                    { fld: 'category'        , hd: 'Category'       , type: 'txt'  , flex: 3 },
                    { fld: 'awarding_date'   , hd: 'Awarding Date'  , type: 'txt'  , flex: 3 },
                    { fld: ''                , hd: 'Delete'         , type: 'act'  , flex: 1,  fmt: 'fa-trash', clr: '#808080', act: onTapPastorQualificationDelete },                    
                ]}
            />
        </FtFrame>
    </FtCanvas>);

    //====================================================================
    // event handlers
    
    function onLoad(): void 
    {   
        setBusy( true );
        FtRapi.callRead( 'pastorRead', pastorId, {
            success: ( result, data ) => {
                setPnPastor( data[0] );
                onSearch();
            },
            error: ( { message } ) => ftPopupMessage( setPopup, "Error", message, { btn: "Close", onClose: () => nav.pop() } ),  
            complete: () => setBusy( false ),
        });
    }

    function onSearch(): void
    {
        setLstQualification( [] );
        setBusy( true );
        FtRapi.callList( 'pastorQualificationSearch', {
            urlParms: { prm_pastor_id: pastorId },
            success: ( result, data ) => setLstQualification( data[0] ),
            error: ( { message } ) => ftPopupMessage( setPopup, "Error", message ),
            complete: () => setBusy( false ),
        });
    }

    function onTapPastorQualificationEdit( { dataId }: FtDgAction ): void
    {
        nav.push( 'QualificationEdit', { pastorId: pastorId, qualId: dataId } );
    }

    function onTapPastorQualificationDelete( { dataId }: FtDgAction ): void
    {
        ftPopupConfirm( setPopup, 'Confirmation', "Are you sure you want to delete this record?", {
            btnA: 'Yes', onA: () => {
                setLstQualification( [] );
                setBusy( true );
                FtRapi.callDelete( 'pastorQualificationDelete', dataId, {
                    success: ( { message }, data ) => {
                        ftPopupMessage( setPopup, "Success", message, { onClose: onSearch } );
                    },
                    error: ( { message } ) => ftPopupMessage( setPopup, "Error", message ),
                    complete: () => setBusy( false ),
                });
            },
            btnB: 'No', onB: () => {}
        });
    }
    
    function onClickNewQualification(): void
    {
        nav.push( 'QualificationEdit', { pastorId: pastorId, qualId: 0 } );
    }

}
