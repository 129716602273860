import ConfRegisterStatusBase from './base/confRegisterStatusBase';

//********************************************************************
// ConfRegisterStatus class
// @FTGEN_PROGRESS: busy
export default class ConfRegisterStatus extends ConfRegisterStatusBase
{
    //====================================================================
    // static data members

    // #FTGEN_CONSTS_A#
    static CRS_NOT_REGISTERED = 1;
    static CRS_REGISTERED_NOTPAID = 2;
    static CRS_REGISTERED_AND_PAID = 3;
    // #FTGEN_CONSTS_Z#

    //====================================================================
    // init
    
    constructor()
    {
        super();
    }
}
