import PastorRetireTypeBase from './base/pastorRetireTypeBase';

//********************************************************************
// PastorRetireType class
// @FTGEN_PROGRESS: busy
export default class PastorRetireType extends PastorRetireTypeBase
{
    //====================================================================
    // static data members

    // #FTGEN_CONSTS_A#
    static PRT_55 = 1;
    static PRT_64 = 2;
    static PRT_65 = 3;
    // #FTGEN_CONSTS_Z#

    //====================================================================
    // init
    
    constructor()
    {
        super();
    }
}
