import AssemblyBase from './base/assemblyBase';

//********************************************************************
// Assembly class
// @FTGEN_PROGRESS: busy
export default class Assembly extends AssemblyBase
{
    //====================================================================
    // static data members

    // #FTGEN_CONSTS_A#
    // #FTGEN_CONSTS_Z#

    //====================================================================
    // init
    
    constructor()
    {
        super();
    }
}
