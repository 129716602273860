import AdminTaskBase from './base/adminTaskBase';

//********************************************************************
// AdminTask class
// @FTGEN_PROGRESS: busy
export default class AdminTask extends AdminTaskBase
{
    //====================================================================
    // static data members

    // #FTGEN_CONSTS_A#
    // #FTGEN_CONSTS_Z#

    //====================================================================
    // init
    
    constructor()
    {
        super();
    }
}
