import PastorPositionBase from './base/pastorPositionBase';

//********************************************************************
// PastorPosition class
// @FTGEN_PROGRESS: busy
export default class PastorPosition extends PastorPositionBase
{
    //====================================================================
    // static data members

    // #FTGEN_CONSTS_A#
    // #FTGEN_CONSTS_Z#

    //====================================================================
    // init
    
    constructor()
    {
        super();
    }
}
