import { MD3LightTheme } from 'react-native-paper';

const appTheme = {
    ...MD3LightTheme,
    dark: false,
    roundness: 2,                                              // roundness of common elements, such as buttons
    colors: {                                                  // https://callstack.github.io/react-native-paper/docs/guides/theming#theme-properties
        ...MD3LightTheme.colors,
        // primary: palette.primary40,
        // primaryContainer: palette.primary90,
        // secondary: palette.secondary40,
        // secondaryContainer: palette.secondary90,
        // tertiary: palette.tertiary40,
        // tertiaryContainer: palette.tertiary90,
        // surface: palette.neutral99,
        // surfaceVariant: palette.neutralVariant90,
        // surfaceDisabled: color(palette.neutral10).alpha(opacity.level2).rgb().string(),
        // background: palette.neutral99,
        // error: palette.error40,
        // errorContainer: palette.error90,
        // onPrimary: palette.primary100,
        // onPrimaryContainer: palette.primary10,
        // onSecondary: palette.secondary100,
        // onSecondaryContainer: palette.secondary10,
        // onTertiary: palette.tertiary100,
        // onTertiaryContainer: palette.tertiary10,
        // onSurface: palette.neutral10,
        // onSurfaceVariant: palette.neutralVariant30,
        // onSurfaceDisabled: color(palette.neutral10).alpha(opacity.level4).rgb().string(),
        // onError: palette.error100,
        // onErrorContainer: palette.error10,
        // onBackground: palette.neutral10,
        // outline: palette.neutralVariant50,
        // outlineVariant: palette.neutralVariant80,
        // inverseSurface: palette.neutral20,
        // inverseOnSurface: palette.neutral95,
        // inversePrimary: palette.primary80,
        // shadow: palette.neutral0,
        // scrim: palette.neutral0,
        // backdrop: color(MD3Colors.neutralVariant20).alpha(0.4).rgb().string(),
    }
};

export default appTheme;

/*
=== old theme =======================================================
import { Colors, configureFonts } from 'react-native-paper';
import color from 'color';
// import { black, white, pinkA400 } from './colors';
// import configureFonts from './fonts';

const appTheme = {
  dark: false,
  roundness: 5,
  colors: {
    primary: '#1976d2',              // '#6200ee',
    accent: '#03dac4',               // checkbox/switch on state color
    background: '#f6f6f6',
    surface: Colors.white,
    error: '#f44336', // '#B00020',
    text: Colors.black,
    onSurface: '#000000',
    disabled: color( Colors.black ).alpha(0.26).rgb().string(),
    placeholder: '#505050',                                      // input floating labels, color( Colors.black ).alpha(0.54).rgb().string()
    backdrop: color( Colors.black ).alpha(0.5).rgb().string(),
    notification: color( Colors.pinkA400 ),
  },
  fonts: configureFonts(),
  animation: {
    scale: 1.0,
  },
};
*/

