import React, { useState, useEffect } from 'react';
import type { FtPageProps, FtDgAction } from '../first/ftTypes';
import Ft from '../first/ft';
import FtRapi from '../first/ftRapi';
import FtFrame from '../first/ftFrame';
import FtCanvas from '../first/ftCanvas';
import FtCon from '../first/ftCon';
import FtText from '../first/ftText';
import FtButton from '../first/ftButton';
import FtDataGrid from '../first/ftDataGrid';
import { ftBusy } from '../first/ftBusy';
import { ftPopupMessage } from '../first/ftPopupMessage';
import { ftPopupConfirm } from '../first/ftPopupOptions';
import Th from '../general/th';

import Justice   from '../models/justice';
import ArcRepo   from '../models/arcRepo';

//********************************************************************
// PageJusticeFileSearch
// @FTGEN_PROGRESS: done
// ...todo: specify props here...
export default function PageJusticeFileSearch( { navigation, options }: FtPageProps ): JSX.Element
{
    //====================================================================
    // hooks: no conditions here
    
    //- hooks: state -----
    const nav = Ft.useNav( navigation );
    const { justiceId } = nav.params ?? { justiceId: 0 };
    const [ popup        , setPopup        ] = useState( <></> ); // placeholder for any popup message/options
    const [ busy         , setBusy         ] = useState( false );
    const [ mdlArcRepo   , setMdlArcRepo   ] = useState( new ArcRepo() );
    const [ pnJustice    , setPnJustice    ] = useState<Justice|null>( null );
    const [ lstRepo      , setLstRepo      ] = useState<ArcRepo[]>( [] );

    //- hooks: effect/startup -----
    useEffect( onLoad, [] );

    //====================================================================
    // render
    
    if ( busy || Ft.eAny( pnJustice ) ) return ftBusy( { popup: popup } );

    return (<FtCanvas {...Th.page}>
        {popup}

        <FtFrame text="Case" {...Th.frame} ss='12|12|12|10|6'>
            <FtText ss="12" text={`Case No : ${pnJustice?.case_no}`}     {...Th.textLg} />
            <FtText ss="12" text={`Member No : ${pnJustice?.member_no}`} {...Th.textLg} />
        </FtFrame>

        <FtCon md='rw' lt='z,a' marB={15} ss='12|12|12|10|6'>
            <FtButton label="Refresh"  onTap={onSearch}       {...Th.button} padR={20}/>
            <FtButton label="New File" onTap={onClickNewFile} {...Th.button} />
        </FtCon>
                
        <FtFrame text='Search Results' {...Th.frame} ss='12'>
            <FtDataGrid {...Th.grid} pgsize={50}
                data={lstRepo}
                cols={[
                    { fld: ''             , hd: 'Download'      , type: 'act'  , flex: 1, fmt: 'fa-cloud-download' , clr: '#808080', act: onTapDownload },
                    { fld: 'store_dttm'   , hd: 'Uploaded'      , type: 'dtm'  , flex: 1, fmt: 'YYYY/MM/DD HH:mm' },
                    { fld: 'title'        , hd: 'Description'   , type: 'txt'  , flex: 5 },
                    { fld: ''             , hd: 'Delete'        , type: 'act'  , flex: 1, fmt: 'fa-trash'          , clr: '#808080', act: onTapFileDelete },
                ]}
            />
        </FtFrame>

    </FtCanvas>);

    //====================================================================
    // event handlers
    
    function onLoad(): void 
    {   
        setBusy( true );
        FtRapi.callRead( 'justiceRead', justiceId, {
            success: ( result, data ) => {
                setPnJustice( data[0] );
                onSearch();
            },
            error: ( { message } ) => ftPopupMessage( setPopup, "Error", message, { btn: "Close", onClose: () => nav.pop() } ),  
            complete: () => setBusy( false ),
        });
    }

    function onSearch(): void
    {
        setLstRepo( [] );
        setBusy( true );
        FtRapi.callList( 'justiceFileSearch', {
            urlParms: { prm_justice_id: justiceId },
            success: ( result, data ) => setLstRepo( data[0] ),
            error: ( { message } ) => ftPopupMessage( setPopup, "Error", message ),
            complete: () => setBusy( false ),
        });
    }

    function onTapFileDelete( { dataId }: FtDgAction ): void
    {
        ftPopupConfirm( setPopup, 'Confirmation', "Are you sure you want to delete this record?", {
            btnA: 'Yes', onA: () => {
                setLstRepo( [] );
                setBusy( true );
                FtRapi.callDelete( 'justiceFileDelete', dataId, {
                    success: ( { message }, data ) => {
                        ftPopupMessage( setPopup, "Success", message, { onClose: onSearch } );
                    },
                    error: ( { message } ) => ftPopupMessage( setPopup, "Error", message ),
                    complete: () => setBusy( false ),
                });
            },
            btnB: 'No', onB: () => {}
        });
    }
    
    function onClickNewFile(): void
    {
        nav.push( 'JusticeFileEdit', { justiceId: justiceId } );
    }

    function onTapDownload( { dataItem }: FtDgAction ): void
    {
        FtRapi.callUrl( 'arcReadImgFile', { repo_key: dataItem.repo_key } );
    }

}
