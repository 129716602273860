import PastorQualificationBase from './base/pastorQualificationBase';

//********************************************************************
// PastorQualification class
// @FTGEN_PROGRESS: busy
export default class PastorQualification extends PastorQualificationBase
{
    //====================================================================
    // static data members

    // #FTGEN_CONSTS_A#
    // #FTGEN_CONSTS_Z#

    //====================================================================
    // init
    
    constructor()
    {
        super();
    }
}
