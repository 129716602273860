import AssemblyRegionBase from './base/assemblyRegionBase';

//********************************************************************
// AssemblyRegion class
// @FTGEN_PROGRESS: busy
export default class AssemblyRegion extends AssemblyRegionBase
{
    //====================================================================
    // static data members

    // #FTGEN_CONSTS_A#
    // #FTGEN_CONSTS_Z#

    //====================================================================
    // init
    
    constructor()
    {
        super();
    }
}
