import GenLookupBase from './base/genLookupBase';

//********************************************************************
// GenLookup class
// @FTGEN_PROGRESS: busy
export default class GenLookup extends GenLookupBase
{
    //====================================================================
    // static data members

    // #FTGEN_CONSTS_A#
    // #FTGEN_CONSTS_Z#

    //====================================================================
    // init
    
    constructor()
    {
        super();
    }
}
