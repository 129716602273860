import GenderBase from './base/genderBase';

//********************************************************************
// Gender class
// @FTGEN_PROGRESS: busy
export default class Gender extends GenderBase
{
    //====================================================================
    // static data members

    // #FTGEN_CONSTS_A#
    static G_MALE = 1;
    static G_FEMALE = 2;
    // #FTGEN_CONSTS_Z#

    //====================================================================
    // init
    
    constructor()
    {
        super();
    }
}
