import PastorHistoryBase from './base/pastorHistoryBase';

//********************************************************************
// PastorHistory class
// @FTGEN_PROGRESS: busy
export default class PastorHistory extends PastorHistoryBase
{
    //====================================================================
    // static data members

    // #FTGEN_CONSTS_A#
    // #FTGEN_CONSTS_Z#

    //====================================================================
    // init
    
    constructor()
    {
        super();
    }
}
