import React, { useState, useEffect } from 'react';
import type { FtPageProps, FtDgAction } from '../first/ftTypes';
import Ft from '../first/ft';
import FtFrame from '../first/ftFrame';
import FtCanvas from '../first/ftCanvas';
import FtSpacer from '../first/ftSpacer';
import FtForm from '../first/ftForm';
import FtFmSubmit from '../first/ftFmSubmit';
import Th from '../general/th';
import Ut from '../general/ut';

//********************************************************************
// PageReportRegionOfficeBearers
// @FTGEN_PROGRESS: done
// ...todo: specify props here...
export default function PageReportRegionOfficeBearers( { navigation, options }: FtPageProps ): JSX.Element
{
    //====================================================================
    // hooks: no conditions here
    
    //- hooks: state -----
    const nav = Ft.useNav( navigation );
    const [ popup     , setPopup     ] = useState( <></> ); // placeholder for any popup message/options
    const [ mdlParms  , setMdlParms  ] = useState( {} as any );

    //- hooks: effect/startup -----
    // useEffect( onLoad, [] );

    //====================================================================
    // render
    
    return (<FtCanvas {...Th.page}>
        {popup}

        <FtFrame text='Report' {...Th.frame} ss='12|12|12|9|4'>
            <FtForm {...Th.form}
                models={{ m: mdlParms }}
                onSubmit={onSubmitReport}
            >
                <FtSpacer />
                <FtFmSubmit label='Generate Report' {...Th.reportBtn} />
                <FtSpacer />
            </FtForm>
        </FtFrame>
    </FtCanvas>);

    //====================================================================
    // event handlers

    function onSubmitReport(): void
    {
        Ut.openUrlSvr( 'reportRegionOfficeBearers' );
    }    
}
