import ArcRepoMediaTypeBase from './base/arcRepoMediaTypeBase';

//********************************************************************
// ArcRepoMediaType class
// @FTGEN_PROGRESS: busy
export default class ArcRepoMediaType extends ArcRepoMediaTypeBase
{
    //====================================================================
    // static data members

    // #FTGEN_CONSTS_A#
    static ARMT_IMAGE = 1;
    static ARMT_VIDEO = 2;
    static ARMT_GENERAL = 3;
    // #FTGEN_CONSTS_Z#

    //====================================================================
    // init
    
    constructor()
    {
        super();
    }
}
