import React, { useState, useEffect } from 'react';
import type { FtPageProps } from '../first/ftTypes';
import Ft from '../first/ft';
import FtRapi from '../first/ftRapi';
import FtFrame from '../first/ftFrame';
import FtCanvas from '../first/ftCanvas';
import FtSpacer from '../first/ftSpacer';
import FtForm from '../first/ftForm';
import FtText from '../first/ftText';
import FtFmSubmit from '../first/ftFmSubmit';
import FtFmUploadFile from '../first/ftFmUploadFile';
import { ftBusy } from '../first/ftBusy';
import { ftPopupMessage } from '../first/ftPopupMessage';
import Th from '../general/th';

import Justice  from '../models/justice';

//********************************************************************
// PageJusticeFileEdit
// @FTGEN_PROGRESS: done
// ...todo: specify props here...
export default function PageJusticeFileEdit( { navigation, options }: FtPageProps ): JSX.Element
{
    //====================================================================
    // hooks: no conditions here
    
    //- hooks: state -----
    const nav = Ft.useNav( navigation );
    const { justiceId } = nav.params ?? { justiceId: 0 };    // unpack parameters passed from calling page
    const [ popup        , setPopup      ] = useState( <></> ); // placeholder for any popup message/options
    const [ busy         , setBusy       ] = useState( false );
    const [ pnJustice    , setPnJustice  ] = useState<Justice|null>( null );
    const [ fileKey      , setFileKey    ] = useState<string>( Ft.randomSha256() );
    const [ mdlUploads   , setMdlUploads ] = useState<any>( { file_key : null } );

    //- hooks: effect/startup -----
    useEffect( onLoad, [] );

    //====================================================================
    // render
    
    if ( busy || Ft.eAny( pnJustice ) ) return ftBusy( { popup: popup } );

    return (<FtCanvas {...Th.page}>
        {popup}

        <FtFrame text="Case" {...Th.frame} ss='12|12|12|10|6'>
            <FtText ss="12" text={`Case No : ${pnJustice?.case_no}`}     {...Th.textLg} />
            <FtText ss="12" text={`Member No : ${pnJustice?.member_no}`} {...Th.textLg} />
        </FtFrame>
                
        <FtFrame text='New Justice Case File' {...Th.frame}>
            <FtForm {...Th.form}
                models={{ m: mdlUploads }}
                onSubmit={onSave}
            >
                <FtFmUploadFile  name='m.file_key'  label='File'  {...Th.field} ss='9' uploadKey={fileKey} onError={onError} ftreq />
                <FtSpacer />
                <FtFmSubmit ss='9:2' label='Save' {...Th.button} />
                <FtSpacer />
            </FtForm>
        </FtFrame>

    </FtCanvas>);

    //====================================================================
    // event handlers
    
    function onLoad(): void
    {
        setBusy( true );
        FtRapi.callRead( 'justiceRead', justiceId, {
            success: ( result, data ) => setPnJustice( data[0] ),
            error: ( { message } ) => ftPopupMessage( setPopup, "Error", message, { btn: "Close", onClose: nav.pop } ),
            complete: () => setBusy( false ),
        });
    }

    function onError( message: string ): void
    {
        ftPopupMessage( setPopup, "Error", message );
    }

    function onSave(): void
    {
        setBusy( true );
        FtRapi.callNew( 'justiceFileNew', {
            urlParms: { 
                justice_id : justiceId,
                file_key  : mdlUploads.file_key,
            },
            success: ( { message }, data ) => ftPopupMessage( setPopup, "Success", message, { btn: "Close", onClose: nav.pop } ),
            error: ( { message } ) => ftPopupMessage( setPopup, "Error", message ),
            complete: () => setBusy( false ),
        });
    }
}