import React, { useEffect } from 'react';
import type { FtPageProps } from '../first/ftTypes';
import Ft from '../first/ft';
import FtRapi from '../first/ftRapi';
import FtCanvas from '../first/ftCanvas';
import FtCon from '../first/ftCon';
import FtImage from '../first/ftImage';
import FtButton from '../first/ftButton';
import Th from '../general/th';
import FtAppInfo from '../first/ftAppInfo';

import im_nob from '../../assets/logo-landing.png';

//********************************************************************
// PageLanding
// @FTGEN_PROGRESS: done
// ...todo: specify props here...
export default function PageLanding( { navigation, options }: FtPageProps ): JSX.Element
{
    //====================================================================
    // hooks: no conditions here
    
    //- hooks: state -----
    const nav = Ft.useNav( navigation );

    //- hooks: effect/startup -----
    useEffect( onLoad, [] );

    //====================================================================
    // render
    
    return (<FtCanvas {...Th.page}>
        <FtImage ss='9|9|6|3|3' src={im_nob} />

        <FtCon ss='12' md='rw' lt='c,a' pad={10}>
            <FtButton label='Login to System' onTap={onTapLogin}  />
        </FtCon>

    </FtCanvas>);

    //====================================================================
    // event handlers
    
    function onLoad(): void 
    {
        if ( FtAppInfo.isDebug ) return; // only force new login session on production
        
        FtRapi.callRead( 'authLoginCheck', 0, {
            success: ( result, data ) => {
                if ( Ft.e( data[0] ) ) 
                    return;
                FtRapi.callNew( 'authLogout', {} );
            },
        });
    }

    function onTapLogin(): void
    {
        nav.replace( 'AuthLogin' );
    }

}
