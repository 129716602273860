import React, { useState, useEffect } from 'react';
import type { FtPageProps, FtDgAction } from '../first/ftTypes';
import Ft from '../first/ft';
import FtRapi from '../first/ftRapi';
import FtFrame from '../first/ftFrame';
import FtCanvas from '../first/ftCanvas';
import FtCon from '../first/ftCon';
import FtSpacer from '../first/ftSpacer';
import FtForm from '../first/ftForm';
import FtFmSubmit from '../first/ftFmSubmit';
import FtFmTextField from '../first/ftFmTextField';
import FtFmDropdownField from '../first/ftFmDropdownField';
import FtButton from '../first/ftButton';
import FtDataGrid from '../first/ftDataGrid';
import { ftBusy } from '../first/ftBusy';
import { ftPopupMessage } from '../first/ftPopupMessage';
import { ftPopupConfirm } from '../first/ftPopupOptions';
import Th from '../general/th';

import GenLookup            from '../models/genLookup';
import AdminTask            from '../models/adminTask';

//********************************************************************
// PageTaskSearch
// @FTGEN_PROGRESS: done
// ...todo: specify props here...
export default function PageTaskSearch( { navigation, options }: FtPageProps ): JSX.Element
{
    //====================================================================
    // hooks: no conditions here

    //- hooks: state -----
    const nav = Ft.useNav( navigation );
    const [ popup         , setPopup         ] = useState( <></> ); // placeholder for any popup message/options
    const [ busy          , setBusy          ] = useState( false );
    const [ mdlTask       , setMdlTask       ] = useState( new AdminTask() );
    const [ lkpCategory   , setLkpCategory   ] = useState<GenLookup[]>( [] );
    const [ lkpUserz      , setLkpUserz      ] = useState<GenLookup[]>( [] );
    const [ lstTask       , setLstTask       ] = useState<AdminTask[]>( [] );

    //- hooks: effect/startup -----
    useEffect( onLoad, [] );

    //====================================================================
    // render

    if ( busy || Ft.eAny( lkpCategory, lkpUserz ) ) return ftBusy( { popup: popup } );

    return (<FtCanvas {...Th.page}>
        {popup}

        <FtCon ss='12|12|12|9' md='rw' lt='z,a' marB={15}>
            <FtButton label="New Task" onTap={onClickNewTask} {...Th.button} />
        </FtCon>

        <FtFrame text='Search Parameters' {...Th.frame}>
            <FtForm {...Th.form}
                models={{ m: mdlTask }}
                onSubmit={onSearch}
            >
                <FtFmTextField     name='m.description'        label='Description'    {...Th.field} />
                <FtFmDropdownField name='m.responsible_id'     label='Responsible'    {...Th.field} data={lkpUserz} />
                <FtFmDropdownField name='m.category_id'        label='Category'       {...Th.field} data={lkpCategory} />
                <FtSpacer />
                <FtFmSubmit ss='3:6:3|9:3' label='Search' {...Th.button} />
                <FtSpacer />
            </FtForm>
        </FtFrame>

        <FtFrame text='Search Results' {...Th.frame} ss='12'>
            <FtDataGrid {...Th.grid} pgsize={50}
                data={lstTask}
                cols={[
                    { fld: ''                , hd: 'Edit'         , type: 'act'  , flex: 1, fmt: 'mc-pencil', clr: '#808080', act: onTapAdminTaskEdit },
                    { fld: 'due_by'          , hd: 'Due By'       , type: 'dtm'  , flex: 3, fmt: 'YYYY/MM/DD HH:mm' },
                    { fld: 'description'     , hd: 'Description'  , type: 'txt'  , flex: 3 },
                    { fld: 'responsible_id'  , hd: 'Responsible'  , type: 'lkp'  , flex: 3, lkp: lkpUserz },
                    { fld: 'category_id'     , hd: 'Category'     , type: 'lkp'  , flex: 3, lkp: lkpCategory },
                    { fld: ''                , hd: 'Delete'       , type: 'act'  , flex: 1, fmt: 'fa-trash', clr: '#808080', act: onTapAdminTaskDelete },
                ]}
            />
        </FtFrame>

    </FtCanvas>);

    //====================================================================
    // event handlers

    function onLoad(): void
    {
        setBusy( true );
        FtRapi.callList( 'userzLookup', {
            success: ( result, data ) => {
                setLkpUserz( data[0] );
                setLkpCategory( data[1] );
            },
            error: ( { message } ) => ftPopupMessage( setPopup, "Error", message, { btn: "Close", onClose: () => nav.pop() } ),
            complete: () => setBusy( false ),
        });
    }

    function onSearch(): void
    {
        setBusy( true );
        setLstTask( [] );
        FtRapi.callList( 'adminTaskSearch', {
            urlParms: {
                prm_description    : mdlTask.description,
                prm_responsible_id : mdlTask.responsible_id,
                prm_category_id    : mdlTask.category_id,
            },
            success: ( result, data ) => setLstTask( data[0] ),
            error: ( { message } ) => ftPopupMessage( setPopup, "Error", message ),
            complete: () => setBusy( false ),
        });
    }

    function onTapAdminTaskEdit( { dataId }: FtDgAction ): void
    {
        nav.push( 'TaskEdit', { taskId: dataId } );
    }

    function onTapAdminTaskDelete( { dataId }: FtDgAction ): void
    {
        ftPopupConfirm( setPopup, 'Confirmation', "Are you sure you want to delete this record?", {
            btnA: 'Yes', onA: () => {
                setLstTask( [] );
                setBusy( true );
                FtRapi.callDelete( 'adminTaskDelete', dataId, {
                    success: ( { message }, data ) => ftPopupMessage( setPopup, "Success", message, { onClose: onSearch } ),
                    error: ( { message } ) => ftPopupMessage( setPopup, "Error", message ),
                    complete: () => setBusy( false ),
                });
            },
            btnB: 'No', onB: () => {}
        });
    }

    function onClickNewTask(): void
    {
        setLstTask( [] );
        nav.push( 'TaskEdit', { taskId: 0 } );
    }

}
